import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'

import { createSession, sendInputData, sendDSData, sendSessionMetaData, sendSessionOption, createMobileDS,
		    sendKeyContainer, createDS, getDSResult, deleteSession, sendTokenPath, getMobileDSBase64Data, createVtcoDs, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputDescription, clearInputFileForDS, clearInputDescription, setInputTextForDS, clearInputTextForDS, setInputTextForDSUintArr,
			setDSData, clearDSData, setPrevisionDsFile, clearPrevisionDsFile, setDSEncodingType, setInputTextForDSDescription,
			setDSDataBase64, clearDSDataBase64, clearDSInputTextDescription, setPrevisionDsText, clearPrevisionDsText, setCreateDSTextExpand,
			setTmpPin, setTmpTwoFactorCode, setErrorData, setShowErrorPopup } from '../actions/localStates'
import { StringToUTF16LEArray, fromByteArray, StringToArray, toByteArray, cipher } from '../actions/stringEncodersFuncs'
import i18next from 'i18next'

import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'
import PinPopup from '../components/body/PinPopup'


class CreateTextDSContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
      		uuid: null,
      		isLoading: false,
      		blobData: null,
      		inputTextValue: "",
      		responseTextValue: "",
      		collapseCreateDSTextShow: "",
      		showPinPopup: false,
      		showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false
	    }

	    this.withToken = this.withToken.bind(this)
	    this.textEncodingHendler = this.textEncodingHendler.bind(this)
	    this.textInputHendler = this.textInputHendler.bind(this)
	    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
	    this.handlePrevisionChange = this.handlePrevisionChange.bind(this)

	    this.copyToClipboard = this.copyToClipboard.bind(this)
	    this.copyTextToClipboard = this.copyTextToClipboard.bind(this)
	    this.createMobileDS = this.createMobileDS.bind(this)

	    this.handleShowPin = this.handleShowPin.bind(this);
		this.handleCreateDSUsingPin = this.handleCreateDSUsingPin.bind(this);
	}

	componentDidMount() {
		this.props.expandedCardsReducer.createTextExpanded ? this.setState({collapseCreateDSTextShow: "card-body collapse show"}) : this.setState({collapseCreateDSTextShow: "card-body collapse"})
	}

	expandCreateDSText() {
		this.props.actions.setCreateDSTextExpand(this.props.expandedCardsReducer.createTextExpanded)
		this.props.expandedCardsReducer.createTextExpanded ? this.setState({collapseCreateDSTextShow: "card-body collapse"}) : this.setState({collapseCreateDSTextShow: "card-body collapse show"})
	}

	textEncodingHendler(e) {
		this.props.actions.setDSEncodingType(e.target.value)
	}

	handleDescriptionChange(e) {
		this.props.actions.setInputTextForDSDescription(e.target.value)
		this.props.actions.clearDSDataBase64()
	}

	textInputHendler(e) {
		this.props.actions.setInputTextForDS(e.target.value)
		this.props.actions.clearDSDataBase64()
	}

	handlePrevisionChange(e) {
		this.props.actions.setPrevisionDsText(e.target.value)
		this.props.actions.clearDSDataBase64()
	}

	cleanUpForm() {
		this.setState({inputTextValue: ""})
		this.setState({responseTextValue: ""})
		this.props.actions.clearInputTextForDS()
		this.props.actions.clearDSInputTextDescription()
		this.props.actions.clearDSDataBase64()
		this.props.actions.clearPrevisionDsText()
	}

	copyTextToClipboard () {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(this.props.createDSDefaultState.textForDS);
	}

	copyToClipboard () {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        textArea.blur();
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(this.props.createDSDefaultState.dsDataInBase64);
	}

	handleShowPin () {
    	this.setState({
            showPinPopup: !this.state.showPinPopup
        });
    }

    handleCreateDSUsingPin () {
    	this.setState({showPinPopup: false})
    	this.createDS();
    }

    createMultipleDSWithSigns () {
    	if (this.props.pkLocalState.showPinInput) {
    		this.handleShowPin()
    	} else {
    		this.createDS();
    	} 
    }

	createMobileDS() {
		var _this = this;
		var arr

		var profileData = {
    		number: this.props.pkLocalState.fullPhoneNumber,
    		position: this.props.pkLocalState.positionId,
    		service:  this.props.pkLocalState.serviceId
    	}

		this.props.createDSDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.createDSDefaultState.textForDS, false)
			: arr = this.props.actions.StringToArray(this.props.createDSDefaultState.textForDS, true)

		if (this.props.createDSDefaultState.previsionDsText.length > 0) {
			try {
	            var arrToSend = this.props.actions.toByteArray(this.props.createDSDefaultState.previsionDsText);
        		var dsData = new Uint8Array(arrToSend)
	        } catch (e) {
	            alert(i18next.t("ds:illegalBase64SignatureFormat"));
	            return;
	        }
	    }

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForDSUintArr(u8Arr)

		this.setState({isLoading: true})
		this.props.actions.createSession(this.props.base.cloudURLForMobileDS)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
          		? this.props.actions.sendDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, dsData)
	            .then(() => {
					return this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState)
			            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
			            .then(() => this.props.actions.getMobileDSBase64Data(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
			            .then((response) => {
			            		if (response !== null) {
				                _this.props.actions.setDSDataBase64(response.base64Data)
		                    	console.log(response)
		                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
		            		} else {
		            			alert(i18next.t("ds:dsResponseFailed"))
		            		}
			            		this.setState({isLoading: false})
			                  })
			            .then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid))
				}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
				? this.props.actions.sendInputData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr)
				.then(() => {
					return this.props.actions.sendDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, dsData)
					.then(() => this.props.actions.sendSessionMetaData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.textForDSDescription)
		            .then(() => this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
		            .then(() => this.props.actions.getMobileDSBase64Data(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
		            .then((response) => {
		            		if (response !== null) {
				                _this.props.actions.setDSDataBase64(response.base64Data)
		                    	console.log(response)
		                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
		            		} else {
		            			alert(i18next.t("ds:dsResponseFailed"))
		            		}
		            		this.setState({isLoading: false})
		                  })
		            .then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid)))
				}) : this.props.actions.sendInputData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr)
				.then(() => {
					return this.props.actions.sendSessionMetaData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.createDSDefaultState.textForDSDescription)
					.then(() => this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData))
		            .then(() => this.props.actions.getMobileDSBase64Data(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId))
		            .then((response) => {
		            		if (response !== null) {
				                _this.props.actions.setDSDataBase64(response.base64Data)
		                    	console.log(response)
		                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
		            		} else {
		            			alert(i18next.t("ds:dsResponseFailed"))
		            		}
		            		this.setState({isLoading: false})
		                  })
		            .then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid))
				})
        })
	}

	withToken(settedToken) {
		var arr, url, description = "", errorMessage;
		var _this = this;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.createDSDefaultState.textForDSDescription.length === 0) {
			description = "signed-data.dat"
		} else {
			description = this.props.createDSDefaultState.textForDSDescription
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		if (this.props.pkLocalState.mobileDSMode) {
			this.createMobileDS();
			return;
		}

		this.props.createDSDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.createDSDefaultState.textForDS, false)
			: arr = this.props.actions.StringToArray(this.props.createDSDefaultState.textForDS, true)

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForDSUintArr(u8Arr)

		if (this.props.defaultState.duplicateSign) {
			if (this.props.createDSDefaultState.previsionDsText.length > 0) {
				try {
		            var arrToSend = this.props.actions.toByteArray(this.props.createDSDefaultState.previsionDsText);
	        		var dsData = new Uint8Array(arrToSend)
		        } catch (e) {
		        	errorMessage = {
                      message: i18next.t("ds:illegalBase64SignatureFormat")
                    }
                    this.props.actions.setErrorData(errorMessage)
                    this.props.actions.setShowErrorPopup(true)

		            // alert(i18next.t("ds:illegalBase64SignatureFormat"));
		            return;
		        }
		    }
		}

		this.setState({isLoading: true})
		this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
        .then((response) => {
          this.setState({uuid: response.ticketUuid})
          console.log(response);
          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
          		? this.props.actions.sendDSData(url, this.state.uuid, dsData, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
	            .then(() => {
					return this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
			            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
			            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
			            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
			            .then((response) => {
			            		if (response !== null) {
			            			var reader = new FileReader();
					                reader.onload = function () {
					                    var arrayBuffer = reader.result;
					                    var uintArray = new Uint8Array(arrayBuffer);
					                    var b64String = _this.props.actions.fromByteArray(uintArray)
					                    _this.props.actions.setDSDataBase64(b64String)
					                }
					                reader.readAsArrayBuffer(response);
			                    	console.log(response)
			                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
			            		} else {
			            			errorMessage = {
			                          message: i18next.t("ds:dsResponseFailed")
			                        }
			                        _this.props.actions.setErrorData(errorMessage)
			                        _this.props.actions.setShowErrorPopup(true)

			            			// alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})
			                  })
			            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
			            .then(() => this.props.actions.setTmpPin(""))
						.then(() => this.props.actions.setTmpTwoFactorCode(""))
				}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
				? this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
				.then(() => {
					return this.props.actions.sendDSData(url, this.state.uuid, dsData, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
					.then(() => this.props.actions.sendSessionMetaData(url, this.state.uuid, description, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
		            .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then((response) => {
		            		if (response !== null) {
		            			var reader = new FileReader();
				                reader.onload = function () {
				                    var arrayBuffer = reader.result;
				                    var uintArray = new Uint8Array(arrayBuffer);
				                    var b64String = _this.props.actions.fromByteArray(uintArray)
				                    _this.props.actions.setDSDataBase64(b64String)
				                }
				                reader.readAsArrayBuffer(response);
		                    	console.log(response)
		                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
		            		} else {
		            			errorMessage = {
		                          message: i18next.t("ds:dsResponseFailed")
		                        }
		                        _this.props.actions.setErrorData(errorMessage)
		                        _this.props.actions.setShowErrorPopup(true)

		            			// alert(i18next.t("ds:dsResponseFailed"))
		            		}
		            		this.setState({isLoading: false})
		                  })
		            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)))
		            .then(() => this.props.actions.setTmpPin(""))
					.then(() => this.props.actions.setTmpTwoFactorCode(""))
				}) : this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
				.then(() => {
					return this.props.actions.sendSessionMetaData(url, this.state.uuid, description, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
					.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then((response) => {
		            		if (response !== null) {
		            			var reader = new FileReader();
				                reader.onload = function () {
				                    var arrayBuffer = reader.result;
				                    var uintArray = new Uint8Array(arrayBuffer);
				                    var b64String = _this.props.actions.fromByteArray(uintArray)
				                    _this.props.actions.setDSDataBase64(b64String)
				                }
				                reader.readAsArrayBuffer(response);
		                    	console.log(response)
		                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
		            		} else {
		            			errorMessage = {
		                          message: i18next.t("ds:dsResponseFailed")
		                        }
		                        _this.props.actions.setErrorData(errorMessage)
		                        _this.props.actions.setShowErrorPopup(true)

		            			// alert(i18next.t("ds:dsResponseFailed"))
		            		}
		            		this.setState({isLoading: false})
		                  })
		            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
		            .then(() => this.props.actions.setTmpPin(""))
					.then(() => this.props.actions.setTmpTwoFactorCode(""))
				})
        })
	}

	selectVtcoSign () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.createVtcoSign()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.createVtcoSign2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	createVtcoSign2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})

		data = {
		    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "SIGNING",
		    "permissionTtlSeconds" : 10,
		    "permissionRepetition" : 2
		}

		this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		var msg = response.message + "\n" + response.techMessage
	    		alert(msg);
	    	} else {
	    		data = {
				    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "SIGNING",
				    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
				}

	    		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    		.then((response) => {
			    	if (response.code !== undefined) {
			    		if (response.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		currentCount = currentCount - 1;

		 					if (currentCount > 0) {
		 						_this.setState({numberOfPinInput: currentCount});
								_this.setState({needToReEnterPin: true});
								_this.setState({showVtcoConfirmPopup: true})
		 					} else {
		 						_this.setState({numberOfPinInput: 0});
		 						_this.setState({needToReEnterPin: false});
								_this.setState({showVtcoConfirmPopup: false})
								_this.setState({isLoading: false})
		 					}
	                	} else {
	                		_this.setState({isLoading: false})
	                		errorMessage = {
		                      message: response.message,
		                      techMessage: response.techMessage
		                    }
		                    _this.props.actions.setErrorData(errorMessage)
		                    _this.props.actions.setShowErrorPopup(true)

				    		// var msg = response.message + "\n" + response.techMessage
				    		// alert(msg);
	                	}
			    	} else {
			    		_this.createVtcoSign();
			    	}
			    })
	    	}
	    })
	}

	createVtcoSign () {
		var _this = this, data, arr, u8Arr, decoder, b64encoded, signatureFormat, errorMessage;
		this.setState({isLoading: true})

		if (this.props.defaultState.xadesType !== "NONE") {
			signatureFormat = this.props.defaultState.xadesType
		} else {
			signatureFormat = this.props.defaultState.cadesType
		}

		if (this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached") {
				this.props.createDSDefaultState.textEncoding === 'UTF-16LE'
					? arr = this.props.actions.StringToUTF16LEArray(this.props.createDSDefaultState.textForDS, false)
					: arr = this.props.actions.StringToArray(this.props.createDSDefaultState.textForDS, true)

				u8Arr = new Uint8Array(arr);

				decoder = new TextDecoder('utf8');
				b64encoded = btoa(decoder.decode(u8Arr));

				data = {
					"detachedSignature" : this.props.createDSDefaultState.previsionDsText,
					"signedData" : b64encoded
				}

				_this.props.actions.convertToAttached(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
				.then((response) => {

					if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    		return;
			    	}

					var jsonObject = {
			        	pin : _this.props.pkLocalState.vtcoPin,
						embedContentTs : true,
						signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
						signatureFormat : signatureFormat,
						embedSignerCertificate : true,
						asicForm : _this.props.defaultState.asicForm,
						dataToSignQualifier: "ALREADY_SIGNED",
						dataToSign : [response.attachedSignature]
				    };

				    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

				    data = {
				        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				        "encryptedData": cipherResult.encryptedData,
				        "secretKey": cipherResult.secretKey
				    }

				    _this.props.actions.createVtcoDs(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
				    .then((response) => {
				    	if (response.code !== undefined) {
				    		_this.setState({isLoading: false})
				    		var msg = response.message + "\n" + response.techMessage
				    		alert(msg);
				    	} else {
				    		data = {
								"attachedSignature" : response[0]
							}

							_this.props.actions.convertToDetached(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
							.then((response) => {
								console.log(response)
								if (response.code !== undefined) {
						    		_this.setState({isLoading: false})
						    		errorMessage = {
				                      message: response.message,
				                      techMessage: response.techMessage
				                    }
				                    _this.props.actions.setErrorData(errorMessage)
				                    _this.props.actions.setShowErrorPopup(true)

						    		// var msg = response.message + "\n" + response.techMessage
						    		// alert(msg);
						    	} else {
						    		_this.props.actions.setDSDataBase64(response.detachedSignature)
							    	_this.setState({isLoading: false})
						    	}
							})
				    	}
				    })
				})
			} else {
				this.props.createDSDefaultState.textEncoding === 'UTF-16LE'
					? arr = this.props.actions.StringToUTF16LEArray(this.props.createDSDefaultState.textForDS, false)
					: arr = this.props.actions.StringToArray(this.props.createDSDefaultState.textForDS, true)

				u8Arr = new Uint8Array(arr);

				decoder = new TextDecoder('utf8');
				b64encoded = btoa(decoder.decode(u8Arr));

				var encodedString = this.props.defaultState.duplicateSign ? this.props.createDSDefaultState.previsionDsText : b64encoded;

				if ((_this.props.defaultState.cadesType === "CADES_BES" || _this.props.defaultState.cadesType === "CADES_X_LONG") && _this.props.defaultState.asicForm === "NONE") {
					var jsonObject = {
			        	pin : _this.props.pkLocalState.vtcoPin,
						embedContentTs : true,
						signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
						signatureFormat : signatureFormat,
						embedSignerCertificate : true,
						asicForm : _this.props.defaultState.asicForm,
						dataToSignQualifier: this.props.defaultState.duplicateSign ? "ALREADY_SIGNED" : "NOT_SIGNED_BEFORE",
						dataToSign : [
							encodedString
						]
				    }
				} else {
					var jsonObject = {
			        	pin : _this.props.pkLocalState.vtcoPin,
						embedContentTs : true,
						signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
						signatureFormat : signatureFormat,
						embedSignerCertificate : true,
						asicForm : _this.props.defaultState.asicForm,
						dataToSignQualifier: this.props.defaultState.duplicateSign ? "ALREADY_SIGNED" : "NOT_SIGNED_BEFORE",
						dataToSign : [
							encodedString
						],
						dataToSignIdentifiers : _this.props.createDSDefaultState.textForDSDescription.length > 0 ? [_this.props.createDSDefaultState.textForDSDescription] : ["signed-data.dat"]
				    }
				}

			    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

			    data = {
			        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
			        "encryptedData": cipherResult.encryptedData,
			        "secretKey": cipherResult.secretKey
			    }

			    _this.props.actions.createVtcoDs(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
	                    _this.setState({isLoading: false})
	                    errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		_this.props.actions.setDSDataBase64(response[0])
				    	console.log(response)
				    	_this.setState({isLoading: false})
			    	}
			    })
			}
	}

	createDS() {
		var _this = this, url;
		var arr, dsData, description = "", errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			if (this.props.pkLocalState.isTwoFactorEnable) {
				var data = {
				    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "SIGNING",
				    "permissionTtlSeconds" : 10,
				    "permissionRepetition" : 2
				}

				this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		this.selectVtcoSign();
			    		return;
			    	}
			    })
			} else {
				this.selectVtcoSign();
				return;
			}
		}
		
		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.createDSDefaultState.textForDSDescription.length === 0) {
			description = "signed-data.dat"
		} else {
			description = this.props.createDSDefaultState.textForDSDescription
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		if (this.props.pkLocalState.mobileDSMode) {
			this.createMobileDS();
			return;
		}

		this.props.createDSDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.createDSDefaultState.textForDS, false)
			: arr = this.props.actions.StringToArray(this.props.createDSDefaultState.textForDS, true)

		if (this.props.defaultState.duplicateSign) {
			if (this.props.createDSDefaultState.previsionDsText.length > 0) {
				try {
		            var arrToSend = this.props.actions.toByteArray(this.props.createDSDefaultState.previsionDsText);
	        		dsData = new Uint8Array(arrToSend)
		        } catch (e) {
		            alert(i18next.t("ds:illegalBase64SignatureFormat"));
		            return;
		        }
		    }
		}

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForDSUintArr(u8Arr)

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				settedToken = {"keyStorePath": this.props.pkLocalState.keyContainer}
				this.withToken(settedToken)
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(url, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
		        .then((response) => {
		          this.setState({uuid: response.ticketUuid})
		          console.log(response);
		          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
		          		? this.props.actions.sendDSData(url, this.state.uuid, dsData, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
			            .then(() => {
							return this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
					            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
					            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
					            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
					            .then((response) => {
					            		if (response !== null) {
				            			var reader = new FileReader();
						                reader.onload = function () {
						                    var arrayBuffer = reader.result;
						                    var uintArray = new Uint8Array(arrayBuffer);
						                    var b64String = _this.props.actions.fromByteArray(uintArray)
						                    _this.props.actions.setDSDataBase64(b64String)
						                }
						                reader.readAsArrayBuffer(response);
				                    	console.log(response)
				                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
				            		} else {
				            			alert(i18next.t("ds:dsResponseFailed"))
				            		}
					            		this.setState({isLoading: false})
					                  })
					            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
					            .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
					            .then(() => this.props.actions.setTmpPin(""))
								.then(() => this.props.actions.setTmpTwoFactorCode(""))
						}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
						? this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
						.then(() => {
							return this.props.actions.sendDSData(url, this.state.uuid, dsData, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
							.then(() => this.props.actions.sendSessionMetaData(url, this.state.uuid, description, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
				            .then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then((response) => {
				            		if (response !== null) {
				            			var reader = new FileReader();
						                reader.onload = function () {
						                    var arrayBuffer = reader.result;
						                    var uintArray = new Uint8Array(arrayBuffer);
						                    var b64String = _this.props.actions.fromByteArray(uintArray)
						                    _this.props.actions.setDSDataBase64(b64String)
						                }
						                reader.readAsArrayBuffer(response);
				                    	console.log(response)
				                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
				            		} else {
				            			alert(i18next.t("ds:dsResponseFailed"))
				            		}
				            		this.setState({isLoading: false})
				                  })
				            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)))
				            .then(() => this.props.actions.setTmpPin(""))
							.then(() => this.props.actions.setTmpTwoFactorCode(""))
				            .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
						}) : this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.textForDSUint8Arr, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
						.then(() => {
							return this.props.actions.sendSessionMetaData(url, this.state.uuid, description, this.props.pkLocalState.useUccConnection ? null : this.props.base.token)
							.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.createDS(url, this.state.uuid, keyContainerPassword, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.getDSResult(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then((response) => {
				            		if (response !== null) {
				            			var reader = new FileReader();
						                reader.onload = function () {
						                    var arrayBuffer = reader.result;
						                    var uintArray = new Uint8Array(arrayBuffer);
						                    var b64String = _this.props.actions.fromByteArray(uintArray)
						                    _this.props.actions.setDSDataBase64(b64String)
						                }
						                reader.readAsArrayBuffer(response);
				                    	console.log(response)
				                    	// alert(i18next.t("ds:dsResponseSuccess": "dsResponseSuccess"))
				            		} else {
				            			alert(i18next.t("ds:dsResponseFailed"))
				            		}
				            		this.setState({isLoading: false})
				                  })
				            .then(() => this.props.actions.deleteSession(url, this.state.uuid, this.props.pkLocalState.useUccConnection ? null : this.props.base.token))
				            .then(() => this.props.actions.setTmpPin(""))
							.then(() => this.props.actions.setTmpTwoFactorCode(""))
						})
		        })
			}
		} else {
			this.withToken(settedToken)
		}
	}

	render() {
		const isTextDSEnabled = this.props.defaultState.duplicateSign
			? this.props.createDSDefaultState.previsionDsText.length > 0 && !this.state.isLoading
			: this.props.createDSDefaultState.textForDS.length > 0 && !this.state.isLoading;
		const isTextDSCopyEnabled = this.props.createDSDefaultState.dsDataInBase64
		const isTextCopyEnabled = this.props.createDSDefaultState.textForDS.length > 0 && !this.state.isLoading;

		return (
			<div style={{paddingTop: "10px"}}>
				<div className="card">
					<div className="card-header" onClick={this.expandCreateDSText.bind(this)} role="button">
						{
							this.props.expandedCardsReducer.createTextExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						}&nbsp;{i18next.t("textData")}
					</div>
					<div className="collapse show">
	                  	<div className={this.state.collapseCreateDSTextShow}>
		                    <div>
		                    	<div className="form">
		                    		<label>{i18next.t("encoding")}</label>
		                    		<div className="form-check-inline">
			                            <label className="form-check-label" style={{marginLeft: "7px"}}>
			                                <input className="form-check-input" value="UTF-16LE" type="radio" checked={this.props.createDSDefaultState.textEncoding === 'UTF-16LE'} onChange={this.textEncodingHendler}/>
			                                <span className="form-check-label">{i18next.t("utf16LeEncoding")}</span>
			                            </label>
			                            <label className="form-check-label" style={{marginLeft: "7px"}}>
			                                <input className="form-check-input" value="UTF-8" type="radio" checked={this.props.createDSDefaultState.textEncoding === 'UTF-8'} onChange={this.textEncodingHendler} />
			                                <span className="form-check-label">{i18next.t("utf8Encoding")}</span>
			                            </label>
				                    </div>
		                    	</div>
			                    {
			                    	this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached" 
			                    	? null
			                    	: <div>
			                    		<label>{i18next.t("ds:textForDsTitle")}</label>
				                        <div className="input-group mb-4">
											<textarea id="dscTextForDs" rows="5" className="form-control" value={this.props.createDSDefaultState.textForDS} onInput={this.textInputHendler}></textarea>
										</div>
										<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-right-padding" style={{"paddingBottom": "10px", "paddingLeft": "0px", "marginTop": "-5px"}}>
				                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isTextCopyEnabled} onClick={this.copyTextToClipboard.bind(this)}>{i18next.t("copyToClipboardTitle")}</button>
				                        </div>

										<label>{i18next.t("ds:metaDataFileForDsTitle")}</label>
				                        <div className="input-group mb-4">
											<input id="dscMetaDataFileForDs" type="text" className="form-control" value={this.props.createDSDefaultState.textForDSDescription} onInput={this.handleDescriptionChange} />
										</div>
			                    	</div>
			                    }
		                    	
								{
									this.props.defaultState.duplicateSign
										? <div>
											<label>{i18next.t("ds:previsionDsBase64Title")}</label>
					                        <div className="input-group mb-4">
												<textarea id="dscTextForDs" rows="5" value={this.props.createDSDefaultState.previsionDsText} onInput={this.handlePrevisionChange} className="form-control"></textarea>
											</div>
										</div> : null
								}
								<div className="row">
									<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
				                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.createMultipleDSWithSigns.bind(this)} disabled={!isTextDSEnabled}>{i18next.t("ds:createDs")}
				                        	{
				                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
				                                : null
				                            }
				                        </button>
			                        </div>
			                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-left-padding">
			                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
			                        </div>
		                        </div>
		                       
		                        <div style={{"paddingTop": "15px"}}>
									<label>{i18next.t("ds:dscBase64Title")}</label>
									<textarea id="dscDsBase64" rows="5" className="form-control" value={this.props.createDSDefaultState.dsDataInBase64} readOnly> </textarea>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-right-padding" style={{"paddingTop": "10px", "paddingLeft": "0px"}}>
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isTextDSCopyEnabled} onClick={this.copyToClipboard.bind(this)}>{i18next.t("copyToClipboardTitle")}</button>
		                        </div>
		                    </div>
	                    </div>
	                </div>
                </div>
                <div>
                    {
                        this.state.showPinPopup
                        ? <PinPopup closePinPopup={this.handleShowPin} createDSUsingPin={this.handleCreateDSUsingPin} />
                        : null
                    }
                    {
						this.state.showVtcoConfirmPopup
						? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"createDsConfirm"} tries={this.state.numberOfPinInput} />
						: null
					}
                </div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.createDSOptionsReducer,
        createDSDefaultState: state.createDSTextReducer,
        connectionStatus: state.connectionStatusReucer,
        base: state.base,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    createSession,
		sendInputData,
		sendSessionMetaData,
		sendSessionOption,
		sendKeyContainer,
		createDS,
		getDSResult,
		deleteSession,
		setInputTextForDS,
		setInputDescription,
		clearInputFileForDS,
		clearInputDescription,
		setDSData,
		clearDSData,
		sendDSData,
		setPrevisionDsFile,
		clearPrevisionDsFile,
		sendTokenPath,
		setDSEncodingType,
		StringToUTF16LEArray,
		fromByteArray,
		StringToArray,
		clearInputTextForDS,
		setInputTextForDSDescription,
		setInputTextForDSUintArr,
		setDSDataBase64,
		clearDSDataBase64,
		clearDSInputTextDescription,
		setPrevisionDsText,
		toByteArray,
		clearPrevisionDsText,
		setCreateDSTextExpand,
		getMobileDSBase64Data,
		createMobileDS,
		setTmpPin, setTmpTwoFactorCode,
		cipher, createVtcoDs, premission2fVtco, premissionVtco,
		setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTextDSContainer);