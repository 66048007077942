import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import saveAs from 'file-saver';
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { languageSwitchItem } from '../actions/locales'
import { cipher, checkExtension } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendDSData, sendSessionMetaData, sendSessionOption,
		    sendKeyContainer, createDS, getDSResult, deleteSession, sendTokenPath, getDSStatus, createMobileDS, getMobileDSData, getCreateMobileDSResult,
		    verifyDSData, getVerifiedData, getQR, createVtcoDs, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputFileForDS, setInputDescription, clearInputFileForDS, clearInputDescription, clearFileList, clearFileListWithDS, setQRList, clearQRList,
			setDSData, clearDSData, setPrevisionDsFile, clearPrevisionDsFile, setDSEncodingType, setFilesForDS, setFilesWithDS, setCreateDSExpand, setCleanUpForm,
			setTmpPin, setTmpTwoFactorCode, setShowPreviewPopup, setErrorData, setShowErrorPopup } from '../actions/localStates'
import i18next from 'i18next'
import { bindActionCreators } from 'redux';

import CreateTextDSContainer from './CreateTextDSContainer'
import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

import QRPopup from '../components/body/QRPopup'
import PinPopup from '../components/body/PinPopup'

import PreviewPopup from './PreviewPopup'

import Dropzone from 'react-dropzone';
const dropzoneRef = createRef();

class CreateDSFilesContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
      		uuid: null,
      		isLoading: false,
      		fileForDSDescription: this.props.createDSDefaultState.fileForDSDescription,
      		blobData: null,
      		downloadIsAvailable: true,
      		files: this.props.createDSDefaultState.fileList || [],
      		filesWithDS: this.props.createDSDefaultState.filesWithDS || [],
      		qrForFiles: this.props.createDSDefaultState.qrList || [],
      		settedToken: null,
      		success: 0,
      		failed: 0,
      		sizeOfFiles: 0,
      		collapseCreateDSShow: "",
      		displayedData: null,
      		showPopup: false,
      		showPinPopup: false,
      		showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false,
			fileIndex: ""
	    }

	    this.onChangeFile = this.onChangeFile.bind(this)
	    this.withToken = this.withToken.bind(this)
	    this.createMobileDS = this.createMobileDS.bind(this)
	    this.createQRData = this.createQRData.bind(this);
	    this.getQR = this.getQR.bind(this);

	    this.handleShowQR = this.handleShowQR.bind(this);
	    this.handleShowPin = this.handleShowPin.bind(this);
	    this.handleOnDrop = this.handleOnDrop.bind(this);

	    this.handleCreateDSUsingPin = this.handleCreateDSUsingPin.bind(this);
	}

	expandCreateDS() {
		this.props.actions.setCreateDSExpand(this.props.expandedCardsReducer.createExpanded)
		this.props.expandedCardsReducer.createExpanded ? this.setState({collapseCreateDSShow: "card-body collapse"}) : this.setState({collapseCreateDSShow: "card-body collapse show"})
	}

	buildFilesMultipleInputSelector(){
		const filesMultipleInput = document.getElementById('filesMultipleUpload');
		return filesMultipleInput;
	}

	componentDidMount() {
		var dropFile = document.getElementById('dropFile');

		this.filesMultipleInput = this.buildFilesMultipleInputSelector();
		this.props.expandedCardsReducer.createExpanded ? this.setState({collapseCreateDSShow: "card-body collapse show"}) : this.setState({collapseCreateDSShow: "card-body collapse"})

		if (dropFile !== null) {
			dropFile.addEventListener('dragenter', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragover', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragleave', function(event) {
				dropFile.style.border = "";
			}, false);

			dropFile.addEventListener('drop', function(event) {
				dropFile.style.border = "";
			}, false);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.duplicateSign !== this.props.defaultState.duplicateSign) {
			this.filesMultipleInput = this.buildFilesMultipleInputSelector();
		}
	}

	handleShowQR (i) {
		var data;

		document.activeElement.blur();

		if (this.state.displayedData === null) {
			data = {
				fileName: this.props.createDSDefaultState.fileList[i].name,
				qrLink: this.state.qrForFiles[i]
			}

			this.setState({
	        	displayedData: data
	        })
		} else {
			this.setState({
	        	displayedData: null
	        })
		}

        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    handleShowPin () {
    	this.setState({
            showPinPopup: !this.state.showPinPopup
        });
    }

    handleCreateDSUsingPin () {
    	this.setState({showPinPopup: false})
    	this.createMultipleDS();
    }

    createMultipleDSWithSigns () {
    	if (this.props.pkLocalState.showPinInput) {
    		this.handleShowPin()
    	} else {
    		this.createMultipleDS();
    	} 
    }

	handleFileInput(e) {
	    e.preventDefault();
	    this.filesMultipleInput.value = null;
	    this.filesMultipleInput.click()
	    document.activeElement.blur()
	}

	onChangeFile(e) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (e.target.files[0] !== undefined && e.target.files.length > 0) {

			if (allFilesSize < 104857600) {
				for (var i = 0; i < e.target.files.length; i += 1) {
					if (e.target.files[i].size === 0 || e.target.files[i].fileSize === 0) {
						alert(i18next.t("ds:zeroFileSize"))
						return;
					}
					
					if (allFilesSize + (e.target.files[i].size || e.target.files[i].fileSize) < 104857600) {
						fileList.push(e.target.files[i])
						allFilesSize += e.target.files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForDS(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	handleOnDrop (files) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (files[0] !== undefined && files.length > 0) {

			if (allFilesSize < 104857600) {
				for (var i = 0; i < files.length; i += 1) {
					if (files[i].size === 0 || files[i].fileSize === 0) {
						alert(i18next.t("ds:zeroFileSize"))
						return;
					}

					if (allFilesSize + (files[i].size || files[i].fileSize) < 104857600) {
						fileList.push(files[i])
						allFilesSize += files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForDS(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	showPreviewPopup (id) {
		console.log(id)
		this.setState({"fileIndex": id})
		this.props.actions.setShowPreviewPopup(true);
	}

	deleteItem(id) {
		const files = this.state.files
		const filesWithDS = this.state.filesWithDS
		const qrForFiles = this.state.qrForFiles
		var allFilesSize = this.state.sizeOfFiles

		allFilesSize = allFilesSize - files[id].size
		this.setState({"sizeOfFiles": allFilesSize})

		files.splice(id, 1)
		filesWithDS.splice(id, 1)
		qrForFiles.splice(id, 1)


		this.setState({"files": files}, () => {
			this.props.actions.setFilesForDS(this.state.files);
		})
		this.setState({"filesWithDS": filesWithDS}, () => {
			this.props.actions.setFilesWithDS(this.state.filesWithDS);
		})
		this.setState({"qrForFiles": qrForFiles})
	}

	onChangePrevisionDsFile(e) {
		if (e.target.files[0] !== undefined && (e.target.files[0].size < 104857600)) {
			this.props.actions.setPrevisionDsFile(e.target.files[0])
			this.props.actions.clearDSData()
		}
	}

	formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit",
            "timeZoneName": "short"
        }

        return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
    }

    constructTimestampDetails(aTs, aTsType, forQR) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let tsaDS = i18next.t("tsaCertificateSerialNumberDS");
        let tsa = i18next.t("tsaCertificateSerialNumber");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }

        let tsaTsTypeString = function () {
            if (aTsType === "content") {
                return tsa;
            }
            if (aTsType === "signature") {
                return tsaDS;
            }
            return "";
        }
        
        if (forQR) {
        	var str = ""
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(tsTypeString() + i18next.t("ds:errorWhileVerifying"));
	            } else {
	            	str += aTs.tsaCertificateSerialNumber ? tsaTsTypeString() + aTs.tsaCertificateSerialNumber + "\n" : "";

		            str += tsTypeString() + ": " + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid")) + "; " + this.formatDate(aTs.dateTime)

	            	result.push(str);
	            }
	        }

	        return result
        } else {
        	if (aTs && aTs.dateTime) {
	            if (aTs.isFailure) {
	                result.push(<div>{tsTypeString() + i18next.t("ds:errorWhileVerifying")}</div>);
	            } else {
	            	result.push(
	            		aTs.tsaCertificateSerialNumber
	            		? <div><span className="font-weight-bold">{tsaTsTypeString()}</span>
		                	{
			            		aTs.tsaCertificateSerialNumber
		                	}
		                </div>
		                : <div><span className="font-weight-bold">{tsTypeString()}</span>
	            			{
			            		": "
			                    + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid"))
			                    + "; "
			                    + this.formatDate(aTs.dateTime)
		                	}
		                </div>
	                );
	            }
	        }
	        return result;
        }
    }

	async getQR (data) {
    	var _this = this, result;
		result = await _this.props.actions.getQR(_this.props.base.qrCodeUrl, data, _this.props.base.token)
		var objectURL = URL.createObjectURL(result);
		return objectURL;
	}

	createQRData (verifyResults, index) {
		var _this = this;

		if (!this.props.defaultState.generateQR) {
			return;
		}
		
		var str = "", tempData;

		async function processArray(array) {
			var qrFiles = _this.state.qrForFiles
			for (const item of array) {

				str += i18next.t("fileName") + _this.props.createDSDefaultState.fileList[index].name + "\n";
				str += (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true") ? i18next.t("ds:qualifiedTitle") + "\n" : i18next.t("ds:unqualifiedTitle") + "\n"

				if (item.signerInfo.ownerCertificateInfo) {
					if (item.signerInfo.ownerCertificateInfo.value.ownerFullName.value) {
						str += i18next.t("ds:signer") + item.signerInfo.ownerCertificateInfo.value.ownerFullName.value + "\n";
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerPosition.value) {
						str += i18next.t("position") + item.signerInfo.ownerCertificateInfo.value.ownerPosition.value + "\n"
					}

					if (item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value) {
						str += i18next.t("organization") + item.signerInfo.ownerCertificateInfo.value.ownerOrganization.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value) {
						str += i18next.t("ds:drfou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value + "\n"
					}

					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value) {
						str += i18next.t("ds:edrpou") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value + "\n"
					}
					
					if (item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value) {
						str += i18next.t("ds:eddrun") + item.signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value + "\n"
					}

					if (item.signerInfo.issuerCertificateInfo.issuerOrganization) {
						str += i18next.t("issuerOrganization") + item.signerInfo.issuerCertificateInfo.issuerOrganization + "\n"
					}
					
					if (item.signerInfo.certificateSerialNumber.value) {
						str += i18next.t("certificateSerialNumberTitle") + item.signerInfo.certificateSerialNumber.value.replace(/\s/g, "") + "\n"
					}

					str += i18next.t("ds:ca") + item.signerInfo.issuerCertificateInfo.value.issuerFullName.value + "\n"
				}
			    
		    	if (item.signingDateTime !== undefined && item.signingDateTime.length > 0) {
			    	str += i18next.t("ds:dsDate") + _this.formatDate(item.signingDateTime) + "\n"
			    }
			    
			    if (item.contentTimestamp !== undefined) {
			    	str += _this.constructTimestampDetails(item.contentTimestamp, "content", true) + "\n"
			    }

		    	if (item.signatureTimestamps && item.signatureTimestamps.length) {
					for (var j = 0; j < item.signatureTimestamps.length; j += 1) {
						str += _this.constructTimestampDetails(item.signatureTimestamps[j], "signature", true) + "\n"
					}
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isQualified.value.length > 0) {
					var qualifiedCertTitle = item.signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:qualifiedCertTitle") + qualifiedCertTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value.length > 0) {
					var QSCDTitle = item.signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")
					str += i18next.t("ds:QSCDTitle") + QSCDTitle + "\n"
				}

				if (item.signerInfo.extensionsCertificateInfo.value.qscdTypeName) {
					var QSCDTypeTitle = item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : item.signerInfo.extensionsCertificateInfo.value.qscdTypeName.value
					str += i18next.t("ds:QSCDTypeTitle") + QSCDTypeTitle + "\n"
				}

				if (item.signatureType) {
            		var signatureType = item.signatureType === null ? i18next.t("ds:notDefined") : i18next.t("ds:" + item.signatureType)
            		str += i18next.t("ds:signatureType") + signatureType + "\n"
            	}

            	if (item.signatureFormat) {
            		var signatureFormat = item.signatureFormat === null ? i18next.t("ds:notDefined") : i18next.t("ds:" + item.signatureFormat)
	            	str += i18next.t("ds:signatureFormat") + signatureFormat + "\n"
            	}

				console.log(str)


				tempData = await _this.getQR(str);
				
    			qrFiles.push(tempData)
    			_this.setState(function(){
				    return {"qrForFiles": qrFiles}
				});

				str = "";
			}
		}

		processArray(verifyResults)	
	}

	async verifyFiles(file, index) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (!_this.props.defaultState.generateQR) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.defaultState.signatureType !== "attached") {
			await this.props.actions.sendInputData(url, this.state.uuid, this.props.createDSDefaultState.fileList[index])
		}
    	
    	await this.props.actions.sendDSData(url, this.state.uuid, file)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.props.verifyDefaultState)
		await this.props.actions.verifyDSData(url, this.state.uuid)
		await this.props.actions.getVerifiedData(url, this.state.uuid)
		.then(async (response) => {
			await this.createQRData(response.verifyResults, index)
		})
		.then(() => {
			console.log(_this.state.qrForFiles);
		})
	}

	async sendFiles(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

    	await this.props.actions.sendInputData(url, this.state.uuid, file)
		await this.props.actions.sendSessionMetaData(url, this.state.uuid, file.name)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState)
        await this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer)
        await this.props.actions.createDS(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword)
        await this.props.actions.getDSResult(url, this.state.uuid)
        .then((response) => {
    		if (response !== null) {
    			var dsFiles = this.state.filesWithDS
				var success = this.state.success + 1
    			dsFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
    		}
        })
        await this.props.actions.getDSStatus(url, this.state.uuid)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var dsFiles = this.state.filesWithDS
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				dsFiles.push(response)
				this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
        	}
        })
	}

	async sendFilesWithToken(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

    	await this.props.actions.sendInputData(url, this.state.uuid, file)
		await this.props.actions.sendSessionMetaData(url, this.state.uuid, file.name)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState)
        await this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null)
        await this.props.actions.createDS(url, this.state.uuid, keyContainerPassword)
        await this.props.actions.getDSResult(url, this.state.uuid)
        .then((response) => {
    		if (response !== null) {
    			var dsFiles = this.state.filesWithDS
				var success = this.state.success + 1
    			dsFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
    		}
        })
        await this.props.actions.getDSStatus(url, this.state.uuid)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var dsFiles = this.state.filesWithDS
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				dsFiles.push(response)
				this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
        	}
        })
	}

	async sendFilesMobileDS (file) {
		var _this = this;
		var profileData = {
    		number: this.props.pkLocalState.fullPhoneNumber,
    		position: this.props.pkLocalState.positionId,
    		service:  this.props.pkLocalState.serviceId
    	}
		if (!_this.props.createDSDefaultState.active) {
			return
		}
    	await this.props.actions.sendInputData(this.props.base.cloudURLForMobileDS, this.state.uuid, file, this.props.base.token)
		await this.props.actions.sendSessionOption(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.defaultState, this.props.base.token)
        await this.props.actions.createMobileDS(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, profileData, this.props.base.token)
        await this.props.actions.getMobileDSData(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, this.props.base.token)
        .then((response) => {
    		if (response !== null) {
    			var dsFiles = this.state.filesWithDS
				var success = this.state.success + 1
    			dsFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
    		}
        })
        await this.props.actions.getCreateMobileDSResult(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.pkLocalState.msspId, this.props.base.token)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var dsFiles = this.state.filesWithDS
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				dsFiles.push(response)
				this.setState(function(){
				    return {"filesWithDS": dsFiles}
				});
        	}
        })
	}

	showError(id) {
		var errorMessage = {
          message: this.state.filesWithDS[id].message,
          techMessage: this.state.filesWithDS[id].failureCause || this.state.filesWithDS[id].techMessage
        }
        this.props.actions.setErrorData(errorMessage)
        this.props.actions.setShowErrorPopup(true)

		// alert(this.state.filesWithDS[id].failureCause)
	}

	setBlobData(data) {
      this.setState({blobData:data});
    }

    clearBlobData() {
      this.setState({blobData:null});
    }

    cleanUpFileForDSInput() {
    	this.props.actions.clearQRList()
    	this.props.actions.clearInputFileForDS()
    	this.props.actions.clearDSData()
	}

	cleanUpPrevisionDsInput() {
    	this.props.actions.clearPrevisionDsFile()
    	this.props.actions.clearDSData()
	}

	cleanUpForm() {
		this.props.actions.setCleanUpForm(false)
		this.props.actions.clearQRList()
		this.props.actions.clearFileList()
		this.props.actions.clearFileListWithDS()
		this.setState({"qrForFiles": []})
		this.setState({"filesWithDS": []})
		this.setState({"files": []})
		this.setState({"displayedData": null})
	}

	createMobileDS() {
		var _this = this
		this.props.actions.clearFileListWithDS()
		this.props.actions.clearQRList();
		this.setState({"qrForFiles": []})
		this.setState({"filesWithDS": []})
		this.setState({"displayedData": null})
		this.setState({isLoading: true})
		this.props.actions.createSession(this.props.base.cloudURLForMobileDS, this.props.base.token)
        .then((response) => {
			this.setState({uuid: response.ticketUuid})
			console.log(response);
        })
        .then(async () => {
        	for (var i = 0; i < this.props.createDSDefaultState.fileList.length; i += 1) {
        		await this.sendFilesMobileDS(this.props.createDSDefaultState.fileList[i])
			}
        })
        .then(async () => {
        	for (var i = 0; i < this.state.filesWithDS.length; i += 1) {
        		await this.verifyFiles(this.state.filesWithDS[i], i)
			}
        })
		.then(() => this.setState({isLoading: false}))
		.then(() => this.props.actions.deleteSession(this.props.base.cloudURLForMobileDS, this.state.uuid, this.props.base.token))
		.then(() => this.props.actions.setFilesWithDS(this.state.filesWithDS))
		.then(() => this.props.actions.setQRList(this.state.qrForFiles))
		.then(() => {
			if (!_this.props.createDSDefaultState.active) {
				return
			}
			if (_this.state.failed > 0) {
				alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles") + "\n" + i18next.t("ds:dsCreatingError") + this.state.failed + i18next.t("ds:dsFileFiles"))
			} else {
				// alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles"))
			}
		})
		.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
	}

	withToken() {
		var _this = this, url, errorMessage;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		this.props.actions.clearFileListWithDS()
		this.props.actions.clearQRList();
		this.setState({"qrForFiles": []})
		this.setState({"filesWithDS": []})
		this.setState({"displayedData": null})
		this.setState({isLoading: true})
		this.props.actions.createSession(url)
        .then((response) => {
			this.setState({uuid: response.ticketUuid})
			console.log(response);
          	
        })
        .then(async () => {
        	for (var i = 0; i < this.props.createDSDefaultState.fileList.length; i += 1) {
        		await this.sendFilesWithToken(this.props.createDSDefaultState.fileList[i])
			}
        })
        .then(async () => {
        	for (var i = 0; i < this.state.filesWithDS.length; i += 1) {
        		await this.verifyFiles(this.state.filesWithDS[i], i)
			}
        })
		.then(() => this.setState({isLoading: false}))
		.then(() => this.props.actions.deleteSession(url, this.state.uuid))
		.then(() => this.props.actions.setFilesWithDS(this.state.filesWithDS))
		.then(() => this.props.actions.setQRList(this.state.qrForFiles))
		.then(() => {
			if (!_this.props.createDSDefaultState.active) {
				return
			}
			if (_this.state.failed > 0) {
				errorMessage = {
					message: i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles") + "\n" + i18next.t("ds:dsCreatingError") + this.state.failed + i18next.t("ds:dsFileFiles")
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

				// alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles") + "\n" + i18next.t("ds:dsCreatingError") + this.state.failed + i18next.t("ds:dsFileFiles"))
			} else {
				// alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles"))
			}
		})
		.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
		.then(() => this.props.actions.setTmpPin(""))
		.then(() => this.props.actions.setTmpTwoFactorCode(""))
	}

	selectVtcoSign () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.createVtcoSign()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			this.setState({isLoading: false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.createVtcoSign2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	createVtcoSign2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})
		
		data = {
		    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "SIGNING",
		    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
		}

		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
		.then((response) => {
	    	if (response.code !== undefined) {
	    		if (response.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		currentCount = currentCount - 1;

 					if (currentCount > 0) {
 						_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
						_this.setState({showVtcoConfirmPopup: true})
 					} else {
 						_this.setState({numberOfPinInput: 0});
 						_this.setState({needToReEnterPin: false});
						_this.setState({showVtcoConfirmPopup: false})
						_this.setState({isLoading: false})
 					}
            		
            	} else {
            		_this.setState({isLoading: false})
            		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)

		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
            	}
	    	} else {
	    		_this.createVtcoSign();
	    	}
	    })
	}

	createVtcoSign () {
		var counter = 0, resultDataArray = [], _this = this, dataToSignIds = [], names = [], signatureFormat, sendData, jsonObject, insertData, resultDSDataArray = [], errorMessage;
		this.setState({isLoading: true})

		if (this.props.defaultState.xadesType !== "NONE") {
			signatureFormat = this.props.defaultState.xadesType
		} else {
			signatureFormat = this.props.defaultState.cadesType
		}

		sendData = function () {
			if ((_this.props.defaultState.cadesType === "CADES_BES" || _this.props.defaultState.cadesType === "CADES_X_LONG") && _this.props.defaultState.asicForm === "NONE") {
				jsonObject = {
		        	pin : _this.props.pkLocalState.vtcoPin,
					embedContentTs : true,
					signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
					signatureFormat : signatureFormat,
					embedSignerCertificate : true,
					asicForm : _this.props.defaultState.asicForm,
					dataToSign : resultDataArray
			    }
			} else {
				jsonObject = {
		        	pin : _this.props.pkLocalState.vtcoPin,
					embedContentTs : true,
					signatureType : _this.props.defaultState["signatureType"].toUpperCase(),
					signatureFormat : signatureFormat,
					embedSignerCertificate : true,
					asicForm : _this.props.defaultState.asicForm,
					dataToSign : resultDataArray,
					dataToSignIdentifiers : dataToSignIds
			    }
			}

		    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

		    var data = {
		        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		        "encryptedData": cipherResult.encryptedData,
		        "secretKey": cipherResult.secretKey
		    }

		    _this.props.actions.createVtcoDs(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
		    .then((response) => {
		    	if (response.code !== undefined) {
		    		_this.setState({isLoading: false})
		    		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)

		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
		    	} else {
		    		insertData = function (index) {
		    			resultDSDataArray.push(response[index])
		    		}

		    		_this.props.createDSDefaultState.fileList.forEach(function (file) {
			            names.forEach(function (name, index) {
			            	if (name === file.name) {
			            		insertData(index)
			            	}
			            })
			        })

		    		_this.setState({"filesWithDS": resultDSDataArray});
			    	_this.props.actions.setFilesWithDS(resultDSDataArray)
			    	_this.setState({isLoading: false})
		    	}
		    })
		}

		function readFile(file) {
		  return new Promise((resolve, reject) => {
		    const reader = new FileReader();

		    reader.onload = res => {
		    var base64String = window.btoa(res.target.result);
		    names.push(file.name)
		      resolve(base64String);
		    };
		    reader.onerror = err => reject(err);

		    reader.readAsBinaryString(file);
		  });
		}

    	async function read(file) {
			resultDataArray.push(await readFile(file));
			dataToSignIds.push(file.name)
			counter += 1;

            if (counter === _this.props.createDSDefaultState.fileList.length) {
            	sendData()
            }
		}

        for (var i = 0; i < this.props.createDSDefaultState.fileList.length; i += 1) {
            read(this.props.createDSDefaultState.fileList[i]);
		}
	}

	createMultipleDS() {
		var _this = this, url, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			if (this.props.pkLocalState.isTwoFactorEnable) {
				var data = {
				    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "SIGNING",
				    "permissionTtlSeconds" : 10,
				    "permissionRepetition" : 2
				}

				this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		this.selectVtcoSign();
			    	}
			    })
			} else {
				this.selectVtcoSign();
			}

			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		if (this.props.pkLocalState.mobileDSMode) {
			this.createMobileDS();
			return;
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
	            let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.withToken()
	        } else {
	        	this.props.actions.clearFileListWithDS()
	        	this.props.actions.clearQRList();
	        	this.setState({"qrForFiles": []})
				this.setState({"filesWithDS": []})
				this.setState({"displayedData": null})
				this.setState({isLoading: true})
				this.props.actions.createSession(url)
		        .then((response) => {
					this.setState({uuid: response.ticketUuid})
					console.log(response);
		          	
		        })
		        .then(async () => {
		        	for (var i = 0; i < this.props.createDSDefaultState.fileList.length; i += 1) {
		        		await this.sendFiles(this.props.createDSDefaultState.fileList[i])
					}
		        })
		        .then(async () => {
		        	for (var i = 0; i < this.state.filesWithDS.length; i += 1) {
		        		await this.verifyFiles(this.state.filesWithDS[i], i)
					}
		        })
				.then(() => this.setState({isLoading: false}))
				.then(() => this.props.actions.deleteSession(url, this.state.uuid))
				.then(() => this.props.actions.setFilesWithDS(this.state.filesWithDS))
				.then(() => this.props.actions.setQRList(this.state.qrForFiles))
				.then(() => {
					if (!_this.props.createDSDefaultState.active) {
						return
					}
					if (_this.state.failed > 0) {
						errorMessage = {
	                      message: i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles") + "\n" + i18next.t("ds:dsCreatingError") + this.state.failed + i18next.t("ds:dsFileFiles")
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

						// alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles") + "\n" + i18next.t("ds:dsCreatingError") + this.state.failed + i18next.t("ds:dsFileFiles"))
					} else {
						// alert(i18next.t("ds:dsSuccessfullyCreated") + this.state.success + i18next.t("ds:dsFileFiles"))
					}
				})
				.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
				.then(() => this.props.actions.setTmpPin(""))
				.then(() => this.props.actions.setTmpTwoFactorCode(""))
	        }
	    } else {
	    	this.setState({"settedToken": settedToken})
	    	this.withToken()
	    }
	}

	createDS() {
		let settedToken
		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			settedToken = {
				keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
			}
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			this.setState({isLoading: true})
			this.props.actions.createSession(this.props.connectionStatus.baseUrl)
	        .then((response) => {
	          this.setState({uuid: response.ticketUuid})
	          console.log(response);
	          return this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "attached"
	          		? this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
		            .then(() => {
						return this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState)
				            .then(() => this.props.actions.sendKeyContainer(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainer))
				            .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
				            .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
				            .then((response) => {
				            		if (response !== null) {
				            			this.props.actions.setDSData(response)
				                    	console.log(response)
				                    	alert(i18next.t("ds:dsResponseSuccess"))
				            		} else {
				            			alert(i18next.t("ds:dsResponseFailed"))
				            		}
				            		this.setState({isLoading: false})
				                  })
				            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
				            .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
					}) : this.props.defaultState.duplicateSign && this.props.defaultState.signatureType === "detached"
					? this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.fileForDS)
					.then(() => {
						return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.previsionDsFile)
						.then(() => this.props.actions.sendSessionMetaData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
			            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.sendKeyContainer(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainer))
			            .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
			            .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setDSData(response)
			                    	console.log(response)
			                    	alert(i18next.t("ds:dsResponseSuccess"))
			            		} else {
			            			alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})
			                  })
			            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid)))
			            .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
					}) : this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.fileForDS)
					.then(() => {
						return this.props.actions.sendSessionMetaData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.createDSDefaultState.fileForDSDescription)
						.then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.sendKeyContainer(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainer))
			            .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
			            .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setDSData(response)
			                    	console.log(response)
			                    	alert(i18next.t("ds:dsResponseSuccess"))
			            		} else {
			            			alert(i18next.t("ds:dsResponseFailed"))
			            		}
			            		this.setState({isLoading: false})
			                  })
			            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
					})
	        })
		} else {
			this.withToken(settedToken)
		}
	}

    getBlobData(id) {
    	var blob, extension = ".p7s", extensionType = {type : 'application/pkcs7-signature'};
    	const b64toBlob = (b64Data, contentType, sliceSize=512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

		if (this.props.defaultState.asicForm !== "NONE") {
			if (this.props.defaultState.asicForm === "ASIC_S") {
				extension = ".asics"
				extensionType = {type : 'application/vnd.etsi.asic-s+zip'}
			} else {
				extension = ".asice"
				extensionType = {type : 'application/vnd.etsi.asic-e+zip'}
			}
		}

		if (this.props.defaultState.xadesType !== "NONE") {
			if (this.props.defaultState.asicForm !== "NONE" && this.props.defaultState.asicForm === "ASIC_E") {
				extension = ".asice"
				extensionType = {type : 'application/vnd.etsi.asic-e+zip'}
			} else {
				extension = ".xml"
				extensionType = {type : 'application/xml'}
			}
		}

	    if (this.props.createDSDefaultState.fileList[id].name.length > 0) {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.createDSDefaultState.filesWithDS[id], extensionType);
	    		saveAs(blob, this.props.createDSDefaultState.fileList[id].name + extension)
	    	} else {
	    		saveAs(this.props.createDSDefaultState.filesWithDS[id], this.props.createDSDefaultState.fileList[id].name + extension)
	    	}
	    } else {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.createDSDefaultState.dsData, extensionType);
	    		saveAs(blob, this.props.createDSDefaultState.previsionDsFileName + extension)
	    	} else {
	    		saveAs(this.props.createDSDefaultState.dsData, this.props.createDSDefaultState.previsionDsFileName)
	    	}
	    }
	}

	render() {
		const isEnabled = this.props.createDSDefaultState.fileList.length > 0 && !this.state.isLoading

		return (
			<div>
	            <div className="card">
					<div className="card-header" onClick={this.expandCreateDS.bind(this)} role="button">
						{
							this.props.expandedCardsReducer.createExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						}&nbsp;{i18next.t("file")}
					</div>
	              	<div className={this.state.collapseCreateDSShow}>
	                    <div className="form-group row">
				            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 order-1">
				            	<ul className="add-files" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none", "padding": "0", "minHeight": "200px"}}>
				            		{
				            			this.props.createDSDefaultState.fileList.map((n, index) => {
				            				var active = index % 2 === 0 ? "evenClassName" : "oddClassName"
										    return (<li key={index} className={active} style={{"padding": "5px"}}>
										    	{
										    		this.props.defaultState.generateQR
										    		? <span className="row">
											    		<span className={(this.state.isLoading || this.state.filesWithDS[index]) ? "col-md-8 col-sm-8 col-xs-8" : "col-md-9  col-sm-9 col-xs-9"} style={{"wordWrap": "break-word", "display": "block"}}>{this.props.base.previewEnabled === "true" && this.props.actions.checkExtension(n.name) ? <i className="fa fa-eye" style={{cursor: "pointer"}} onClick={this.showPreviewPopup.bind(this, index)}>&nbsp;</i> : null}{n.name}</span>
											    		<span className="col-md-1 col-sm-1 col-xs-1" onClick={this.deleteItem.bind(this, index)}><i className="fa fa-trash" title={i18next.t("deleteTitle")} style={{"cursor": "pointer"}} aria-hidden="true"></i></span>
												    	<span className="col-md-1 col-sm-1 col-xs-1">
												    	{
												    		(this.state.filesWithDS[index] !== undefined && this.state.filesWithDS[index].failureCause)
												    			? <i className="fa fa-exclamation-triangle" title={i18next.t("errorTitle")} style={{"cursor": "pointer"}} onClick={this.showError.bind(this, index)}></i>
												    			: this.state.filesWithDS[index]
												    			? <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer"}} onClick={this.getBlobData.bind(this, index)}></i>
												    			: null
												    	}
												    	{
												    		this.state.isLoading && !this.state.filesWithDS[index]
												    			? <i className="fa fa-spinner fa-pulse"></i>
												    			: null
												    	}
												    	</span>
												    	<span className="col-md-1 col-sm-1 col-xs-1" onClick={this.handleShowQR.bind(this, index)}>
												    	{
												    		this.state.qrForFiles[index]
												    			? <i className="fa fa-qrcode" style={{"cursor": "pointer"}} aria-hidden="true"></i>
												    			: null
												    	}
												    	</span>
												    </span>
												    : <span className="row">
											    		<span className={(this.state.isLoading || this.state.filesWithDS[index]) ? "col-md-9 col-sm-9 col-xs-9" : "col-md-10  col-sm-10 col-xs-10"} style={{"wordWrap": "break-word", "display": "block"}}>{this.props.base.previewEnabled === "true" && this.props.actions.checkExtension(n.name) ? <i className="fa fa-eye" style={{cursor: "pointer"}} onClick={this.showPreviewPopup.bind(this, index)}>&nbsp;</i> : null}{n.name}</span>
											    		<span className="col-md-1 col-sm-1 col-xs-1" onClick={this.deleteItem.bind(this, index)}><i className="fa fa-trash" title={i18next.t("deleteTitle")} style={{"cursor": "pointer"}} aria-hidden="true"></i></span>
												    	<span className="col-md-1 col-sm-1 col-xs-1">
												    	{
												    		(this.state.filesWithDS[index] !== undefined && this.state.filesWithDS[index].failureCause)
												    			? <i className="fa fa-exclamation-triangle" title={i18next.t("errorTitle")} style={{"cursor": "pointer"}} onClick={this.showError.bind(this, index)}></i>
												    			: this.state.filesWithDS[index]
												    			? <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer"}} onClick={this.getBlobData.bind(this, index)}></i>
												    			: null
												    	}
												    	{
												    		this.state.isLoading && !this.state.filesWithDS[index]
												    			? <i className="fa fa-spinner fa-pulse"></i>
												    			: null
												    	}
												    	</span>
												    </span>
										    	}
										    	
										    	
										    	</li>)
										})
				            		}
				            	</ul>
				            </div>
				            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 no-left-padding order-0 order-lg-12" style={{"marginBottom": "10px"}}>

				            	{
				            		this.props.mobileModeReducer.isMobileDevice
				            		? <button className="btn btn-default btn-block" disabled={this.state.isLoading} onClick={this.handleFileInput.bind(this)}>{i18next.t("dsAddFileFilesTitle")}</button>
				            		: <Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop}>
				                        {({getRootProps, getInputProps}) => (
				                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone-md" id="dropFile">
				                                <input {...getInputProps()} />
				                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitle")}</p>
				                            </div>
				                        )}
				                    </Dropzone>
				            	}

				            </div>
				            <input id="filesMultipleUpload" type="file" onChange={this.onChangeFile.bind(this)} style={{"display": "none"}} multiple />
			            </div>
			            <div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
		                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.createMultipleDSWithSigns.bind(this)} disabled={!isEnabled}>{i18next.t("ds:createDs")}
		                        	{
		                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
		                                : null
		                            }
		                        </button>
	                        </div>
	                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-left-padding">
	                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
	                        </div>
                        </div>
                        {
                        	this.props.defaultState.cleanUpForm
                        	? this.cleanUpForm()
                        	: null
                        }
                    </div>
                </div>
                <CreateTextDSContainer />
                <div>
                    {
                        this.state.showPopup
                        ? <QRPopup closeQRPopup={this.handleShowQR} data={this.state.displayedData} />
                        : null
                    }
                </div>
                <div>
                    {
                        this.state.showPinPopup
                        ? <PinPopup closePinPopup={this.handleShowPin} createDSUsingPin={this.handleCreateDSUsingPin} />
                        : null
                    }
                </div>
                {
					this.state.showVtcoConfirmPopup
					? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"createDsConfirm"} tries={this.state.numberOfPinInput} />
					: null
				}
				{
					this.props.base.showPreviewPopup
					? <PreviewPopup file={this.state.fileIndex} />
					: null
				}
            </div>
      )
	}
}


function mapStateToProps(state) {
    return {
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.createDSOptionsReducer,
        createDSDefaultState: state.createDSInputReducer,
        connectionStatus: state.connectionStatusReucer,
        verifyDefaultState: state.verifyDSOptionsReducer,
        base: state.base,
        expandedCardsReducer: state.expandedCardsReducer,
        mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
		createSession,
		sendInputData,
		sendSessionMetaData,
		sendSessionOption,
		sendKeyContainer,
		createDS, getQR,
		getDSResult,
		deleteSession,
		setInputFileForDS,
		setInputDescription,
		clearInputFileForDS,
		clearInputDescription,
		setDSData,
		clearDSData,
		sendDSData,
		setPrevisionDsFile,
		clearPrevisionDsFile,
		sendTokenPath,
		setDSEncodingType,
		setFilesForDS,
		setFilesWithDS,
		clearFileList,
		clearFileListWithDS,
		getDSStatus,
		setCreateDSExpand,
		createMobileDS, getMobileDSData,
		getCreateMobileDSResult,
		verifyDSData, getVerifiedData,
		setQRList, clearQRList,
		setCleanUpForm,
		setTmpPin, setTmpTwoFactorCode,
		createVtcoDs, cipher,
		premission2fVtco, premissionVtco,
		setShowPreviewPopup, checkExtension,
		setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDSFilesContainer);