
const connectionStatusDafaultState = {
    status: false,
    baseUrl: null,
    uccConnection: false,
    cloudConnection: false
}

const connectionStatusReucer = (state = connectionStatusDafaultState, { type, payload }) => {

    switch (type) {
    	case "CONNECTION_STATUS": {
		    return {
		        ...state,
		        status: payload.reponseStatus,
		        baseUrl: payload.baseUrl
		    }
		}

        case "SET_UCC_CONNECTION_STATUS": {
            return {
                ...state,
                uccConnection: payload
            }
        }

        case "SET_CLOUD_CONNECTION_STATUS": {
            return {
                ...state,
                cloudConnection: payload
            }
        }

        case "SET_BASE_URL": {
            return {
                ...state,
                status: payload.reponseStatus,
                baseUrl: payload.baseUrl
            }
        }

        default:
            return state;
    }

}

export default connectionStatusReucer