import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import i18next from 'i18next'

import { setShowPreviewPopup, setShowPreviewPopupVerify } from '../actions/localStates'
import { createContext, uploadContextDataBinary, getDataByIdBinary, deleteContext } from '../actions/api'

class PreviewPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
          link: null,
          uploaded: false,
          contextId: null,
          isImage: false,
          fileName: null
      };

      this.close = this.close.bind(this);
      this.hideTitle = this.hideTitle.bind(this);
  }

  componentDidMount () {
    console.log(this.props.createDSDefaultState.fileList[this.props.file])
    var contextId, _this = this, dataFileName, fileExtension, extensions, viewer, link;

    this.props.actions.createContext("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", "")
    .then((response) => {
      this.setState({"contextId": response.contextId})
      this.setState({"isImage": false})
     contextId = response.contextId;

     if (this.props.file.type !== undefined) {
        dataFileName = this.props.defaultState.fileWithDSName.lastIndexOf(".") < 2 ? this.props.defaultState.fileWithDSName : this.props.defaultState.fileWithDSName.substr(0, this.props.defaultState.fileWithDSName.lastIndexOf("."));
     } else {
        dataFileName = _this.props.createDSDefaultState.fileList[this.props.file].name || _this.props.createDSDefaultState.fileList[this.props.file].fileName
     }

     this.setState({fileName: dataFileName})

     fileExtension = dataFileName.substring(dataFileName.lastIndexOf('.') + 1, dataFileName.length) || dataFileName;
     console.log(fileExtension)

     fileExtension = fileExtension.toLowerCase();

      extensions = {
        gDocs:['txt','rtf'],
        office:['doc','docx','xls','xlsx'],
        viewerJs:['odt','ods','pdf'],
        standartViewer:['jpg', 'jpeg', 'png', 'bmp']
      }

      for(var i in extensions) {
        if(extensions[i].indexOf(fileExtension) >= 0) {
          viewer = i;
          break;
        }
      }

     _this.props.actions.uploadContextDataBinary("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", contextId, _this.props.file.type !== undefined ? _this.props.file : _this.props.createDSDefaultState.fileList[this.props.file], dataFileName, "")
     .then((response) => {
       _this.props.actions.getDataByIdBinary("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", contextId, dataFileName, "")
       .then((response) => {

        // eslint-disable-next-line
        switch(viewer){
          case 'gDocs':
            link = 'https://docs.google.com/viewer?url=' + encodeURIComponent("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/" + contextId + "/data/" + dataFileName) + '&embedded=true&hl=';
            break;
          case 'office':
            link = 'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/" + contextId + "/data/" + dataFileName);
            break;
          case 'viewerJs':
            link = '../ViewerJS/index.html?type=' + fileExtension + '#https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/' + contextId + "/data/" + dataFileName;
            break;

          case 'standartViewer':
            link = 'https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1/context/' + contextId + "/data/" + dataFileName;
            _this.setState({"isImage": true})
            break;

          case 'default':
            break;
        }

         _this.setState({"link": link})
         _this.setState({"uploaded": true})
       })
     })
    })
  }

  componentWillUnmount () {
  }

  hideTitle () {
    setTimeout(() => {
      var iframe = document.getElementById("iframeId");
      if (iframe === null) {
        return;
      }

      var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
      var name = innerDoc.getElementById("documentName");
      name.style.display = "none"
    }, 100);
  }

  close () {
    this.props.actions.deleteContext("https://ccs-dev-api.cipher.com.ua/ccs/swissknife/api/v1", this.state.contextId, "")
    .then((response) => {
      console.log(response)
      this.setState({"contextId": null})
      this.props.actions.setShowPreviewPopupVerify(false);
      this.props.actions.setShowPreviewPopup(false);
    })
    
  }

  render() {
      return (
          <div className='popup'>
              <div className={"popup_inner_user_token_pin"}>
                <div style={{textAlign: "right"}}>
                  <i className="fa fa-times-circle" style={{fontSize: "28px", cursor: "pointer"}} onClick={this.close}></i>
                </div>
                  <div className="col-12" style={{marginTop: "5px", paddingRight: "0px", paddingLeft: "10px"}}>
                      {
                        this.state.uploaded
                        ? this.state.isImage
                          ? <img src={this.state.link} alt={this.state.fileName} width='100%' height='100%'></img>
                          : <iframe src={this.state.link} id="iframeId" onLoad={this.hideTitle()} title={this.state.fileName} width='780' height='550' scrolling="no" allowtransparency="yes" allowFullScreen webkitallowfullscreen></iframe>
                        : null
                      }
                      
                      {/*<label style={{marginLeft: "15px", marginRight: "15px"}}>{i18next.t("sessionEndTitle")}</label>
                      <div style={{float: "right", marginRight: "15px", paddingTop: "10px"}}>
                          <button className="btn btn-info" id="submitPin" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.login}>{i18next.t("continueLabel")}</button>
                          <button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}} onClick={this.cancel}>{i18next.t("cancelLabel")}</button>
                      </div>*/}
                      {/*<button className="btn btn-outline-info" style={{marginLeft: "5px", marginBottom: "30px"}}>{i18next.t("close")}</button>*/}
                  </div>
              </div>
          </div>
      );
    }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        createDSDefaultState: state.createDSInputReducer,
        defaultState: state.verifyDSInputReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
      setShowPreviewPopup, createContext, uploadContextDataBinary,
      getDataByIdBinary, deleteContext, setShowPreviewPopupVerify
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPopup);
