import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import i18next from 'i18next'
import { getListMobileDSProviders, getOwnerProfilesList, getMobileCertificate, getMobileCertificateData, changeUrl } from '../actions/api'
import { setSelectedMode, setMobileDSProviders, setMsspId, setPhoneNumber, setOwnerOrganization, clearOwnerOrganization,
            setMobileSignatureCert, startTic, setDSActive, setIsActive, setPositionId, setServiceId, setMobileDSMode,
            setFullPhoneNumber, clearFullPhoneNumber, clearPhoneNumber, setCertificateInfoKeyAgreement, setCertificateInfo } from '../actions/localStates'

class MobileDSContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
		  selectedMode: this.props.pkLocalState.selectedMode,
          isLoading: false,
          isCertLoading: false,
		  operatorsCodes: null,
          prefix: null,
          operatorsTitle: null
		}

		this.onDropdownModeSelected = this.onDropdownModeSelected.bind(this);
		this.onDropdownProviderSelected = this.onDropdownProviderSelected.bind(this);
        this.setPrefix = this.setPrefix.bind(this);
        this.startWork = this.startWork.bind(this);
        this.keyUpHandler = this.keyUpHandler.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.startUseKey = this.startUseKey.bind(this);
        this.setSelectedProfile = this.setSelectedProfile.bind(this);
        this.cleanUpForm = this.cleanUpForm.bind(this)
	}

	componentDidMount() {
		var _this = this;
        document.addEventListener("keydown", this.keyUpHandler, false);

        this.props.actions.clearOwnerOrganization();
        this.props.actions.clearFullPhoneNumber();
        this.props.actions.clearPhoneNumber();

        this.props.actions.changeUrl(this.props.base.urlUCC, this.props.base.urlCloud, this.props.mobileModeReducer.isMobileDevice, this.props.base.token, this.props.pkLocalState.mobileDSMode)
        this.props.actions.getListMobileDSProviders(_this.props.base.cloudURLForMobileDS, _this.props.base.token)
        .then((response) => {
            console.log(response);
            _this.props.actions.setMobileDSProviders(response);
        })
        .then(() => {
            _this.setState({operatorsCodes: _this.props.pkLocalState.listMobileDSProviders[0].operatorPrefixes})
            _this.props.actions.setMsspId(_this.props.pkLocalState.listMobileDSProviders[0].msspId)
            _this.setState({operatorsTitle: this.props.pkLocalState.listMobileDSProviders[0].msspId})
        })
        .then(() => {
            _this.setState({prefix: _this.props.pkLocalState.listMobileDSProviders[0].operatorPrefixes[0]})
        })
	}

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyUpHandler);
    }

    keyUpHandler(e) {
        if (e.keyCode === 13) {
            if (this.props.pkLocalState.ownersOrganization && (!this.state.isLoading && !this.state.isCertLoading)) {
                document.getElementById("startWork").click();
                document.activeElement.blur()
            } else if (this.state.prefix && this.props.pkLocalState.phoneNumber.length > 6 && (!this.state.isLoading && !this.state.isCertLoading)) {
                document.getElementById("getProfiles").click();
                document.activeElement.blur()
            } else {
                return
            }
        }
    }

    handlePhoneNumberChange(e) {
    	const re = /^[0-9\b]+$/;
	    if (e.target.value === '' || re.test(e.target.value)) {
	      	this.props.actions.setPhoneNumber(e.target.value)
	    }
        this.props.actions.clearOwnerOrganization()
    }

	onDropdownModeSelected (e) {
    	e.preventDefault();
        this.props.actions.setSelectedMode(e.target.value)
        this.props.actions.setMobileDSMode(false)
    }

    onDropdownProviderSelected (e) {
    	this.setState({operatorsCodes: this.props.pkLocalState.listMobileDSProviders[e.target.value].operatorPrefixes})
        this.setState({prefix: this.props.pkLocalState.listMobileDSProviders[e.target.value].operatorPrefixes[0]})
        this.setState({operatorsTitle: this.props.pkLocalState.listMobileDSProviders[e.target.value].msspId})
        this.props.actions.setMsspId(this.props.pkLocalState.listMobileDSProviders[e.target.value].msspId)
        this.props.actions.clearFullPhoneNumber();
        this.props.actions.clearPhoneNumber();
    }

    setPrefix (e) {
        this.setState({prefix: e.target.value})
    }

    setSelectedProfile (e) {
        this.props.actions.setPositionId(this.props.pkLocalState.ownersOrganization[e.target.value].positionId)
        this.props.actions.setServiceId(this.props.pkLocalState.ownersOrganization[e.target.value].serviceId)
    }

    cleanUpForm() {
        this.setState({operatorsCodes: this.props.pkLocalState.listMobileDSProviders[0].operatorPrefixes})
        this.setState({prefix: this.props.pkLocalState.listMobileDSProviders[0].operatorPrefixes[0]})
        this.props.actions.setMsspId(this.props.pkLocalState.listMobileDSProviders[0].msspId)
        this.setState({operatorsTitle: this.props.pkLocalState.listMobileDSProviders[0].msspId})
        this.props.actions.clearFullPhoneNumber();
        this.props.actions.clearPhoneNumber();
        this.props.actions.clearOwnerOrganization();
    }

    startWork () {
    	var phoneNumber = "38" + this.state.prefix + this.props.pkLocalState.phoneNumber
    	this.props.actions.setFullPhoneNumber(phoneNumber)
        this.setState({isLoading: true})
        this.props.actions.getOwnerProfilesList(this.props.base.cloudURLForMobileDS, this.props.pkLocalState.msspId, phoneNumber, this.props.base.token)
        .then((response) => {
            console.log(response);
            if (response.failureCause !== undefined && response.failureCause.length > 0) {
                alert(response.message)
            } else {
                this.props.actions.setOwnerOrganization(response)
                this.props.actions.setPositionId(response[0].positionId)
                this.props.actions.setServiceId(response[0].serviceId)
            }
            this.setState({isLoading: false})
        })
    }

    startUseKey () {
    	let profileData = {
    		number: this.props.pkLocalState.fullPhoneNumber,
    		position: this.props.pkLocalState.positionId,	
    		service: this.props.pkLocalState.serviceId	
    	}

        this.setState({isCertLoading: true})
        this.props.actions.getMobileCertificateData(this.props.base.cloudURLForMobileDS, this.props.pkLocalState.msspId, profileData, this.props.base.token)
        .then((response) => {
            if (response !== null || undefined) {
                this.props.actions.setCertificateInfo(response)
                console.log(response);
            }
        })
        .then(() => this.props.actions.getMobileCertificate(this.props.base.cloudURLForMobileDS, this.props.pkLocalState.msspId, profileData, this.props.base.token))
        .then((response) => {
            console.log(response);
            if (response.base64Data !== undefined) {
                this.props.actions.setMobileSignatureCert(response)
                this.props.actions.startTic(this.props.pkLocalState.settedTime)
                this.props.actions.setDSActive(true)
                this.props.actions.setIsActive(true)
            } else {
                alert(response.message)
            }
            
            this.setState({isCertLoading: false})
        })
    }

	render() {
        const isEnabled = (this.state.prefix && this.props.pkLocalState.phoneNumber.length > 6 && (!this.state.isLoading && !this.state.isCertLoading)) ? true : false
        const isStartWorkEnabled = (this.props.pkLocalState.ownersOrganization && (!this.state.isLoading && !this.state.isCertLoading)) ? true : false
		let mode = this.props.connectionStatus.cloudConnection ? parseInt(this.props.pkLocalState.selectedMode, 10) : 0;
		return (
			<div className="card-body">
                <div className="form-group row">
                    <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:keyTypeLabel")}</label>
                    <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                        <select type="select" id="kt-input" disabled={this.state.isLoading} className="form-control" style={{textAlign: "center", textAlignLast: "center"}} defaultValue={mode} onChange={this.onDropdownModeSelected}>
                                <option key={0} value={0} selected={mode === 0}>{i18next.t("pk:fileOnDiskTitle")}</option>
                                {
                                  !this.props.mobileModeReducer.isMobileDevice
                                  ? <option key={1} value={1} selected={mode === 1}>{i18next.t("pk:pkcsDevicesActiveTitle")}</option>
                                  : null
                                }
                                {
                                  !this.props.mobileModeReducer.isMobileDevice 
                                  ? <option key={2} value={2} selected={mode === 2}>{i18next.t("pk:pkcsDevicesPassiveTitle")}</option>
                                  : null
                                }
                                {
                                  this.props.connectionStatus.cloudConnection
                                  ? <option key={3} value={3} selected={mode === 3}>{i18next.t("mobileDSTitle")}</option>
                                  : null
                                }
                                {
                                    !this.props.mobileModeReducer.isMobileDevice && this.props.connectionStatus.cloudConnection && this.props.base.cipherHSMEnabled === "true"
                                    ? <option key={4} value={4} selected={mode === 4}>{i18next.t("cipherHSMTitle")}</option>
                                    : null
                                }
                                {
                                    !this.props.mobileModeReducer.isMobileDevice && this.props.connectionStatus.cloudConnection && this.props.base.depositSignEnabled === "true"
                                    ? <option key={5} value={5} selected={mode === 5}>{i18next.t("pk:depositsignLabel")}</option>
                                    : null
                                }
                        </select>
                    </div>
                </div>

                <div className="form-group row">
	                <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("providersTitle")}</label>
	                <div className="col-lg-8 col-md-12 col-sm-12">
	                    {
	                        this.props.pkLocalState.listMobileDSProviders ? <select type="select" className="form-control" disabled={this.state.isLoading} onChange={this.onDropdownProviderSelected} style={{textAlign: "center", textAlignLast: "center"}}>
	                            {
	                              this.props.pkLocalState.listMobileDSProviders.map((child, index) =>
	                                <option key={index} value={index} selected={this.state.operatorsTitle === child.msspId}>{child.msspTitle}</option>)
	                            }          
	                        </select> : null
	                    }
	                </div>
	            </div>
            	{
            		this.state.operatorsCodes
            		? <div className="form-group row">
            			<label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("phoneNumberTitle")}</label>
	            		<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 row no-letf-margin">
	            			<select className="form-control col-lg-3 col-md-3 col-sm-3 col-xs-3" style={{"marginBottom": "10px"}} disabled={this.state.isLoading} onChange={this.setPrefix}>
							{
		                      	this.state.operatorsCodes.map((code, index) =>
		                        	<option key={index} value={code} selected={this.state.prefix === code}>{code}</option>
		                        )
		                    }
							</select>
							<input type="text" id="phoneNumber" value={this.props.pkLocalState.phoneNumber} disabled={this.state.isLoading} onChange={this.handlePhoneNumberChange} maxLength="7" className="form-control col-lg-9 col-md-9 col-sm-9 col-xs-9" />
						</div>
					</div>
					: null
            	}
                {
                    this.props.pkLocalState.ownersOrganization
                    ? <div className="form-group row">
                        <label className="col-lg-4 col-md-12 col-sm-12 col-form-label">{i18next.t("profileTitle")}</label>
                        <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 row no-letf-margin">
                            <select className="form-control col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onChange={this.setSelectedProfile}>
                            {
                                this.props.pkLocalState.ownersOrganization.map((org, index) =>
                                    <option key={index} value={index}>{org.title}</option>
                                )
                            }
                            </select>
                        </div>
                    </div>
                    : null
                }
                <div className="form-group row">
                    {
                        this.props.pkLocalState.ownersOrganization
                        ? null
                        : <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12" style={{"paddingBottom": "10px"}}>
                            <button id="getProfiles" className="btn btn-default col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{"cursor": "pointer"}} disabled={!isEnabled} onClick={this.startWork}>{i18next.t("getProfilesTitle")}
                                {
                                    this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                    : null
                                }
                            </button>
                        </div>
                    }
                    {
                        this.props.pkLocalState.ownersOrganization
                        ? <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12" style={{"paddingBottom": "10px"}}>
                            <button id="startWork" className="btn btn-default col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{"cursor": "pointer"}} disabled={!isStartWorkEnabled} onClick={this.startUseKey}>{i18next.t("startWorkTitle")}
                                {
                                    this.state.isCertLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                    : null
                                }
                            </button>
                        </div>
                        : null
                    }
                    <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 no-left-padding">
                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
                    </div>
                </div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        locales: state.i18n,
        localesReducer: state.localesReducer,
        dafaultState: state.dafaultState,
        keyContainers: state.keyContainers,
        selectedKey: state.selectedContainer,
        clientKCState: state.clientKCState,
        pkLocalState: state.privateKeyReducer,
        certificateInfo: state.certificateInfo.certInfoSignature,
        connectionStatus: state.connectionStatusReucer,
        certificateInfoKeyAgreement: state.certificateInfo.certInfoKeyAgreement,
        base: state.base,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSelectedMode,
    	getListMobileDSProviders,
    	setMobileDSProviders,
        setMsspId,
        setPhoneNumber,
        getOwnerProfilesList,
        setOwnerOrganization,
        getMobileCertificate,
        setMobileSignatureCert,
        startTic, setDSActive, setIsActive,
        setPositionId, setServiceId,
        setMobileDSMode,
        clearOwnerOrganization,
        setFullPhoneNumber, clearFullPhoneNumber,
        clearPhoneNumber, getMobileCertificateData,
        setCertificateInfoKeyAgreement, setCertificateInfo,
        changeUrl
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileDSContainer);