const dafaultState = {
    vpnKeyToken: null,
    vpnKeyTokenSetted: false,
    secret: "",
    tokenData: null,
    profileFields: null,
    resultKey: null,
    errorResponse: null,
    allFieldsFiled: true
}

export default function (state=dafaultState, { type, payload }) {
	switch(type) {
		case "SET_VPN_TOKEN": {
			return {
				...state,
				vpnKeyToken: payload,
				vpnKeyTokenSetted: true
			}
		}

		case "CLEAR_VPN_TOKEN": {
			return {
				...state,
				vpnKeyToken: null,
				vpnKeyTokenSetted: false
			}
		}

		case "SET_VPN_TOKEN_DATA": {
			return {
				...state,
				tokenData: payload
			}
		}

		case "CLEAR_VPN_TOKEN_DATA": {
			return {
				...state,
				vpnKeyToken: null
			}
		}

		case "SET_SECRET": {
			return {
				...state,
				secret: payload
			}
		}

		case "CLEAR_SECRET": {
			return {
				...state,
				secret: ""
			}
		}

		case "SET_PROFILE_FIELDS": {
			return {
				...state,
				profileFields: payload
			}
		}

		case "CLEAR_PROFILE_FIELDS": {
			return {
				...state,
				profileFields: null
			}
		}

		case "SET_RESULT_KEY": {
			return {
				...state,
				resultKey: payload
			}
		}

		case "CLEAR_RESULT_KEY": {
			return {
				...state,
				resultKey: null
			}
		}

		case "SET_ERROR_RESPONSE": {
			return {
				...state,
				errorResponse: payload
			}
		}

		case "CLEAR_ERROR_RESPONSE": {
			return {
				...state,
				errorResponse: null
			}
		}

		case "SET_ALL_FIELDS_FILED": {
			return {
				...state,
				allFieldsFiled: payload
			}
		}

		default:
			return state;
	}
}