
const createTextDSDafaultState = {
    textForDS: "",
    textForDSName: "",
    textForDSDescription: "",
    textForDSSelected: false,
    dsDataInBase64: "",
    previsionDsTextSelected: false,
    previsionDsTextName: "",
    previsionDsText: "",
    textEncoding: "UTF-16LE",
    textForDSUint8Arr: ""
}

const createDSTextReducer = (state = createTextDSDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_INPUT_TEXT_FOR_DS": {
		    return {
		        ...state,
		        textForDS: payload,
		        textForDSSelected: true,
		    }
		}

        case "SET_INPUT_TEXT_FOR_DS_UINT8_ARR": {
            return {
                ...state,
                textForDSUint8Arr: payload
            }
        }

        case "CLEAR_INPUT_TEXT_FOR_DS_UINT8_ARR": {
            return {
                ...state,
                textForDSUint8Arr: payload
            }
        }

		case "CLEAR_INPUT_TEXT_FOR_DS": {
		    return {
		        ...state,
		        textForDS: "",
		        textForDSSelected: false
		    }
		}

        case "SET_PREVISION_DS_TEXT_DATA": {
            return {
                ...state,
                previsionDsText: payload,
                previsionDsTextSelected: true
            }
        }

        case 'SET_DS_ENCODING_TYPE': {
            return {
                ...state,
                textEncoding: payload
            }
        }

        case "CLEAR_PREVISION_DS__TEXT_DATA": {
            return {
                ...state,
                previsionDsText: "",
                previsionDsTextSelected: false
            }
        }

        case 'SET_DS_INPUT_TEXT_DESCRIPTION': {
            return {
                ...state,
                textForDSDescription: payload,
            }
        }

        case 'SET_DS_DATA_BASE_64': {
            return {
                ...state,
                dsDataInBase64: payload,
            }
        }

        case 'CLEAR_DS_DATA_BASE_64': {
            return {
                ...state,
                dsDataInBase64: "",
            }
        }

        case 'CLEAR_DS_INPUT_TEXT_DESCRIPTION': {
            return {
                ...state,
                textForDSDescription: "",
            }
        }

        default:
            return state;
    }

}

export default createDSTextReducer