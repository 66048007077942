
const encryptDefaultState = {
    fileList: [],
    encryptedFiles: []
}

const encryptFilesReducer = (state = encryptDefaultState, { type, payload }) => {

    switch (type) {

        case "SET_ENCRYPTED_FILES": {
            return {
                ...state,
                encryptedFiles: payload
            }
        }

        case "SET_FILES_FOR_ENCRYPT": {
            return {
                ...state,
                fileList: payload
            }
        }

        case "CLEAR_FILES_FOR_ENCRYPT_LIST": {
            return {
                ...state,
                fileList: []
            }
        }

        case "CLEAR_ENCRYPTED_FILES": {
            return {
                ...state,
                encryptedFiles: []
            }
        }

        default:
            return state;
    }

}

export default encryptFilesReducer