
const encryptTextDefaultState = {
    textForEncrypt: "",
    textDataInBase64: "",
    textEncoding: "UTF-16LE",
    textForEncryptUint8Arr: ""
}

const encryptTextReducer = (state = encryptTextDefaultState, { type, payload }) => {

    switch (type) {
    	case "SET_INPUT_TEXT_FOR_ENCRYPT": {
		    return {
		        ...state,
		        textForEncrypt: payload
		    }
		}

        case "SET_INPUT_TEXT_FOR_ENCRYPT_UINT8_ARR": {
            return {
                ...state,
                textForEncryptUint8Arr: payload
            }
        }

        case "CLEAR_INPUT_TEXT_FOR_ENCRYPT_UINT8_ARR": {
            return {
                ...state,
                textForEncryptUint8Arr: ""
            }
        }

		case "CLEAR_INPUT_TEXT_FOR_ENCRYPT": {
		    return {
		        ...state,
		        textForEncrypt: ""
		    }
		}

        case 'SET_ENCRYPT_ENCODING_TYPE': {
            return {
                ...state,
                textEncoding: payload
            }
        }

        case 'SET_ENCRYPT_DATA_BASE_64': {
            return {
                ...state,
                textDataInBase64: payload,
            }
        }

        case 'CLEAR_ENCRYPT_DATA_BASE_64': {
            return {
                ...state,
                textDataInBase64: "",
            }
        }

        default:
            return state;
    }

}

export default encryptTextReducer