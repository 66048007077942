import React, { Component } from 'react';
// import i18next from 'i18next'

class Downloads extends Component {
// <small>
//     <span id="downloadJwsaByRef">{i18next.t("downloadJwsaByRef" : "downloadJwsaByRef")}</span><br/>
//     <a href={this.props.url.urlUCCAgent} id="personalDsService">{i18next.t("personalDsService" : "personalDsService")}</a>
// </small>
	render () {
		if (this.props.connectionStatus) {
			return (<div></div>);
		} else {
			return (
				<div id="topScreen">
			        <div className="col-xs-10 col-xs-offset-1">
			            <h3 className="text-center">
			                
			            </h3>
			        </div>
			    </div>
			);
		}
	}
}

export default Downloads