
const encryptDafaultState = {
    fileCertInput: null,
    fileCertInputSelected: false,
    certInputName: "",
    fileForEncrypt: null,
    fileForEncryptSelected: false, 
    fileForEncryptName: "",
    encData: null
}

const encryptReducer = (state = encryptDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_CERTIFICATE_INPUT_FILE": {
		    return {
		        ...state,
		        fileCertInput: payload,
		        certInputName: payload.name,
		        fileCertInputSelected: true,
		    }
		}

		case "CLEAR_CERTIFICATE_INPUT_FILE": {
		    return {
		        ...state,
		        fileCertInput: null,
		        certInputName: "",
		        fileCertInputSelected: false,
		    }
		}

        case 'SET_INPUT_FILE_FOR_ENCRYPT': {
            return {
                ...state,
                fileForEncrypt: payload,
                fileForEncryptName: payload.name,
                fileForEncryptSelected: true
            }
        }

        case 'CLEAR_INPUT_FILE_FOR_ENCRYPT': {
            return {
                ...state,
                fileForEncrypt: null,
                fileForEncryptName: "",
                fileForEncryptSelected: false
            }
        }

        case 'SET_ENCRYPTED_DATA': {
            return {
                ...state,
                encData: payload
            }
        }

        case 'CLEAR_ENCRYPTED_DATA': {
            return {
                ...state,
                encData: null
            }
        }

        default:
            return state;
    }

}

export default encryptReducer