
const dafaultState = {
    expandDSType: true,
    expandTSVerify: false,
    expandDataVerifyTs: false,
    signatureType: "detached",
    signatureTsVerifyOption: "verifyIfPresent",
    dataTsVerifyOption: "verifyIfPresent",
    showUpgradeCard: false,
    cadesType: "CAdESXLong",
    generateQR: false
}

const verifyDSOptionsReducer = (state = dafaultState, { type, payload }) => {

    switch (type) {
    	case 'EXPAND_DS_TYPE': {
		    return {
		        ...state,
		        expandDSType: payload
		    }
		}

        case 'EXPAND_TS_VERIFY': {
            return {
                ...state,
                expandTSVerify: payload
            }
        }

        case 'EXPAND_DATA_VERIFY_TS': {
            return {
                ...state,
                expandDataVerifyTs: payload
            }
        }

        case 'SET_SIGNATURE_TYPE': {
            return {
                ...state,
                signatureType: payload
            }
        }

        case 'SET_TS_CHECK': {
            return {
                ...state,
                signatureTsVerifyOption: payload
            }
        }

        case 'SET_GENERATE_QR': {
            return {
                ...state,
                generateQR: payload
            }
        }

        case 'SET_TS_VERIFY_TYPE': {
            return {
                ...state,
                dataTsVerifyOption: payload
            }
        }

        case 'SHOW_UPGARADE_CARD': {
            return {
                ...state,
                showUpgradeCard: payload
            }
        }

        case 'SET_CADES_TYPE': {
            return {
                ...state,
                cadesType: payload
            }
        }

        default:
            return state;
    }

}

export default verifyDSOptionsReducer


