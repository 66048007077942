const dafaultState = {
    keysProfiles: null,
    selectedProfile: "",
    init: true
}

export default function (state=dafaultState, { type, payload }) {
	switch(type) {
		case "SET_KEYS_PROFILES": {
			return {
				...state,
				keysProfiles: payload
			}
		}

		case "SET_PROFILE": {
			return {
				...state,
				selectedProfile: payload
			}
		}

		case "SET_INIT": {
			return {
				...state,
				init: payload
			}
		}

		default:
			return state;
	}
}