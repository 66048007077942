
const decryptTextDefaultState = {
    textForDecrypt: "",
    textDataInBase64: "",
    textEncoding: "UTF-16LE",
    textForDecryptUint8Arr: "",
    decryptText: ""
}

const decryptTextReducer = (state = decryptTextDefaultState, { type, payload }) => {

    switch (type) {
    	case "SET_INPUT_TEXT_FOR_DECRYPT": {
		    return {
		        ...state,
		        textForDecrypt: payload
		    }
		}

        case "SET_DECRYPT_TEXT": {
            return {
                ...state,
                decryptText: payload
            }
        }

        case "CLEAR_DECRYPT_TEXT": {
            return {
                ...state,
                decryptText: ""
            }
        }

        case "SET_INPUT_TEXT_FOR_DECRYPT_UINT8_ARR": {
            return {
                ...state,
                textForDecryptUint8Arr: payload
            }
        }

        case "CLEAR_INPUT_TEXT_FOR_DECRYPT_UINT8_ARR": {
            return {
                ...state,
                textForDecryptUint8Arr: ""
            }
        }

		case "CLEAR_INPUT_TEXT_FOR_DECRYPT": {
		    return {
		        ...state,
		        textForDecrypt: ""
		    }
		}

        case 'SET_DECRYPT_ENCODING_TYPE': {
            return {
                ...state,
                textEncoding: payload
            }
        }

        case 'SET_DECRYPT_DATA_BASE_64': {
            return {
                ...state,
                textDataInBase64: payload,
            }
        }

        case 'CLEAR_DECRYPT_DATA_BASE_64': {
            return {
                ...state,
                textDataInBase64: "",
            }
        }

        default:
            return state;
    }

}

export default decryptTextReducer