import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import saveAs from 'file-saver';

import i18next from 'i18next'

class QRPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {

	    }

	    this.close = this.close.bind(this);
	    this.downloadImage = this.downloadImage.bind(this);
	}

	componentDidMount() {

	}

	downloadImage () {
		saveAs(this.props.data.qrLink, this.props.data.fileName + ".png")
	}

	close () {
		this.props.closeQRPopup()
	}

	render() {
		return (
			<div className='popup'>
				<div className="popup_inner_qr">
					<div className="col-12" style={{paddingTop: "15px"}}>
						<img src={this.props.data.qrLink} style={{width: "300px", height: "300px"}} alt="qr" />
                    </div>
                    <div className="col-12" style={{paddingLeft: "20px", paddingRight: "20px"}}>
                    	<button className="btn btn-default btn-block" style={{marginTop: "5px"}} onClick={this.downloadImage}>{i18next.t("download")}</button>
                    </div>
                    <div className="col-12" style={{paddingLeft: "20px", paddingRight: "20px"}}>
                    	<button className="btn btn-default btn-block" style={{marginTop: "10px", marginBottom: "10px"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
                    </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(QRPopup);