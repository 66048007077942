
const upgradeTextDSDefaultState = {
    baseText: "",
    baseTextSelected: false,
    textDSInBase64: "",
    textDSInBase64Selected: false,
    textEncoding: "UTF-16LE",
    textForDSUint8Arr: "",
    upgradedDSInBase64: ""
}

const upgradeTextDSReducer = (state = upgradeTextDSDefaultState, { type, payload }) => {

    switch (type) {
    	case "SET_BASE_TEXT_FOR_UPGRADE": {
		    return {
		        ...state,
		        baseText: payload,
		        baseTextSelected: true,
		    }
		}

        case "CLEAR_BASE_TEXT_FOR_UPGRADE": {
            return {
                ...state,
                baseText: "",
                baseTextSelected: false
            }
        }

        case "SET_BASE_64_DS_FOR_UPGRADE": {
            return {
                ...state,
                textDSInBase64: payload,
                textDSInBase64Selected: true,
            }
        }

        case "CLEAR_BASE_64_DS_FOR_UPGRADE": {
            return {
                ...state,
                textDSInBase64: "",
                textDSInBase64Selected: false
            }
        }

        case "SET_INPUT_TEXT_FOR_UPGRADE_UINT8_ARR": {
            return {
                ...state,
                textForDSUint8Arr: payload
            }
        }

        case "CLEAR_INPUT_TEXT_FOR_UPGRADE_UINT8_ARR": {
            return {
                ...state,
                textForDSUint8Arr: ""
            }
        }

        case 'SET_DS_ENCODING_TYPE': {
            return {
                ...state,
                textEncoding: payload
            }
        }

        case 'SET_TEXT_DS_DATA_BASE_64': {
            return {
                ...state,
                textDSInBase64: payload,
            }
        }

        case 'CLEAR_TEXT_DS_DATA_BASE_64': {
            return {
                ...state,
                textDSInBase64: "",
            }
        }

        case 'SET_UPGRADED_DS_IN_BASE_64': {
            return {
                ...state,
                upgradedDSInBase64: payload,
            }
        }

        case 'CLEAR_UPGRADED_DS_IN_BASE_64': {
            return {
                ...state,
                upgradedDSInBase64: "",
            }
        }

        default:
            return state;
    }

}

export default upgradeTextDSReducer