import React, { Component } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'
import { createSession, sendInputData, sendDSData, sendSessionMetaData, sendSessionOption,
		    sendKeyContainer, createDS, getDSResult, deleteSession, sendTokenPath } from '../actions/api'
import { setInputFileForDS, setInputDescription, clearInputFileForDS, clearInputDescription,
			setDSData, clearDSData, setPrevisionDsFile, clearPrevisionDsFile, setDSEncodingType } from '../actions/localStates'

import { bindActionCreators } from 'redux';

import CreateDSOptionsContainer from './CreateDSOptionsContainer'
import CreateDSFilesContainer from './CreateDSFilesContainer'
import CreateDSAddToExistContainer from './CreateDSAddToExistContainer'

class CreateDSContainer extends Component {

	render() {
		return (
	        <div>
	            <div className="row" style={{paddingTop: "10px"}}>
					<CreateDSOptionsContainer />
					<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
						{
							this.props.defaultState.duplicateSign 
							? <CreateDSAddToExistContainer /> : <CreateDSFilesContainer />
						}
	                </div>
		        </div>
	        </div>
      	)
	}
}


function mapStateToProps(state) {
    return {
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultState: state.createDSOptionsReducer,
        createDSDefaultState: state.createDSInputReducer,
        createDSTextReducer: state.createDSTextReducer,
        connectionStatus: state.connectionStatusReucer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
		createSession,
		sendInputData,
		sendSessionMetaData,
		sendSessionOption,
		sendKeyContainer,
		createDS,
		getDSResult,
		deleteSession,
		setInputFileForDS,
		setInputDescription,
		clearInputFileForDS,
		clearInputDescription,
		setDSData,
		clearDSData,
		sendDSData,
		setPrevisionDsFile,
		clearPrevisionDsFile,
		sendTokenPath,
		setDSEncodingType
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDSContainer);