import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { languageSwitchItem } from '../../actions/locales'
import { bindActionCreators } from 'redux'
import Tabs from './Tabs'
import Pane from './Tab'
import { getSupportedCA, getConnecteds, getKeysProfiles } from '../../actions/api'
import { setPassiveTokenPath, setActiveTokenPath, setShowKeyGenTab, setIsLoggedIn, setAuthLogin, setAuthPassword, setToken, setTwoFactorEnabledAuth,
		clearTwoFactorEnabledAuth, setTwoFactorCodeAuth, clearTwoFactorCodeAuth, setIs2fEnabled, setCleanUpAll } from '../../actions/localStates'
import VerifyDSContainer from "../../containers/VerifyDSContainer"
import EncryptContainer from "../../containers/EncryptContainer"
import DecryptContainer from "../../containers/DecryptContainer"
import CreateDSContainer from "../../containers/CreateDSContainer"
import PrivateKeyContainer from "../../containers/PrivateKeyContainer"
import KeyGenerator from "../../containers/KeyGeneratorContainer"
import VPNKeyGenerator from "../../containers/VPNKeyGeneratorContainer"
import ErrorPopup from './ErrorPopup'

const navTabs = [<PrivateKeyContainer />, <VerifyDSContainer />, <CreateDSContainer />, <EncryptContainer />, <DecryptContainer />]

class Body extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
    		listCSK: [],
    		navCollapsed: false,
    		selected: 0,
    		needCheck: false,
    		alertShowed: false
    	}
    	this.navLabels = ["privateKey", "verifyDs", "createDs", "encrypt", "decrypt"]

    	this.check = this.check.bind(this)
    	this.disableTab = this.disableTab.bind(this)

	}

	getInitialState() {
	    return {
	        selected: this.props.selected
	    };
	}

	expandNavbar() {
		this.setState({navCollapsed: !this.state.navCollapsed})
	}

	componentDidMount() {
		if (this.props.base.authEnabled === "true") {
			this.activityWatcher();
		}
	}

	activityWatcher () {
        var _this = this;
        
        var refreshIntervalId = setInterval(function () {
            if (_this.props.base.authEnabled === "true" && _this.props.base.isLoggedIn) {
                if (_this.props.base.expireTime < (Date.now() + _this.props.base.timeDelta)) {
                	_this.props.actions.setIsLoggedIn(false)
                	_this.props.actions.setAuthLogin("")
                	_this.props.actions.setAuthPassword("")
                	_this.props.actions.setToken("")
                	_this.props.actions.setTwoFactorEnabledAuth(false)
		 			_this.props.actions.clearTwoFactorEnabledAuth()
		 			_this.props.actions.setTwoFactorCodeAuth("")
		 			_this.props.actions.clearTwoFactorCodeAuth()
		 			_this.props.actions.setIs2fEnabled(false)
		 			_this.props.actions.setCleanUpAll(true)
		 			clearInterval(refreshIntervalId);
                }
            }
        }, 1000);
        

        function activity() {
            _this.setState({"secondsSinceLastActivity": 0});
        }

        var activityEvents = [
            'mousedown', 'mousemove', 'keydown',
            'scroll', 'touchstart'
        ];

        activityEvents.forEach(function(eventName) {
            document.addEventListener(eventName, activity, true);
        });
    }

	componentDidUpdate(nextProps) {
		if (this.props.mobileModeReducer.mobileDeviceMode) {
			if (this.props.isActiveState.isActive === true && nextProps.isActiveState.isActive === false) {
				this.setState({selected: 0})
			}
		}
	}

	check () {
		if (this.props.connectionStatus.status) {
			if (!this.props.base.showKeyGenTab && !this.state.needCheck) {
				if (this.props.base.generateKeys === "true") {
					this.props.actions.getKeysProfiles(this.props.connectionStatus.baseUrl, !this.props.privateKeyReducer.useUccConnection ? null : this.props.base.token)
					.then((response) => {
						if (response !== null && !this.props.privateKeyReducer.mobileDSMode) {
							this.props.actions.setShowKeyGenTab(true)
							this.setState({"needCheck": true})
						} else {
							this.props.actions.setShowKeyGenTab(false)
							this.setState({"needCheck": true})
						}
					})
				}
			}
		}
	}

	disableTab () {
		if (this.props.base.showKeyGenTab) {
			this.props.actions.setShowKeyGenTab(false)
			this.setState({"needCheck": false})
		}
	}

	handleClick(index, event) {
	    event.preventDefault();
	    this.setState({
	      selected: index
	    });
	    document.activeElement.blur()
	    if (this.state.navCollapsed) {
	    	this.expandNavbar()
	    }
	}

	labels(child, index) {
      let activeClass = (this.state.selected === index ? 'nav-link active' : 'nav-link');
      return (
        child !== null ?
          <li key={index} className="nav-item">
            <a href={null} style={{"cursor": "pointer"}}
              className={activeClass}
              onClick={this.handleClick.bind(this, index)}>
              {child}
            </a>
          </li>
        : null
      );
    }

	render() {		
		var label = this.navLabels[this.state.selected];

		this.props.connectionStatus.uccConnection ? this.check() : this.disableTab()

		// if (!this.state.alertShowed && !this.props.mobileModeReducer.mobileDeviceMode) {

		// 	if (this.props.base.bodyIsMounted && !this.props.connectionStatus.uccConnection) {
		// 		alert("Для роботи із захищеним носієм, необхідно запустити \"Агент ЄСКО\". Попередньо встановивши Java 8.")
		// 		this.setState({"alertShowed": true})
		// 	}
			
		// }

		if (this.props.connectionStatus.status) {
			return (
				<div className="container">
					{
                        this.props.mobileModeReducer.mobileDeviceMode
                        ?
							<div>
							<nav className="navbar navbar-expand-lg navbar-light bg-light">
							  <a className="navbar-brand d-lg-none d-xl-block" href={null}>{i18next.t(label)}</a>
							  <button className="navbar-toggler" type="button" onClick={this.expandNavbar.bind(this)}>
							    <span className="navbar-toggler-icon"></span>
							  </button>
							  <div className={this.state.navCollapsed ? "collapse-show navbar-collapse" : "collapse navbar-collapse"} id="collapsibleNavbar">
							    <ul className="navbar-nav">
							    	<li key={0} className="nav-item">
							            <a href={null} style={{"cursor": "pointer"}}
							              className={this.state.selected === 0 ? 'nav-link active' : 'nav-link'}
							              onClick={this.handleClick.bind(this, 0)}>
							              {i18next.t("privateKey")}
							            </a>
							        </li>
							        <li key={1} className="nav-item">
							            <a href={null} style={{"cursor": "pointer"}}
							              className={this.state.selected === 1 ? 'nav-link active' : 'nav-link'}
							              onClick={this.handleClick.bind(this, 1)}>
							              {i18next.t("verifyDs")}
							            </a>
							        </li>				
									{
										(this.props.isActiveState.isActive && (this.props.certificateInfo.certInfoSignature || this.props.isActiveState.mobileSignatureCert))
										? <li key={2} className="nav-item">
								            <a href={null} style={{"cursor": "pointer"}}
								              className={this.state.selected === 2 ? 'nav-link active' : 'nav-link'}
								              onClick={this.handleClick.bind(this, 2)}>
								              {i18next.t("createDs")}
								            </a>
								        </li>
										: null
									}
									{
										((this.props.isActiveState.isActive && this.props.certificateInfo.certInfoKeyAgreement) && !this.props.privateKeyReducer.showPinInput)
										? <li key={3} className="nav-item">
								            <a href={null} style={{"cursor": "pointer"}}
								              className={this.state.selected === 3 ? 'nav-link active' : 'nav-link'}
								              onClick={this.handleClick.bind(this, 3)}>
								              {i18next.t("encrypt")}
								            </a>
								        </li>
										: null
									}
									{
										((this.props.isActiveState.isActive && this.props.certificateInfo.certInfoKeyAgreement) && !this.props.privateKeyReducer.showPinInput)
										? <li key={4} className="nav-item">
								            <a href={null} style={{"cursor": "pointer"}}
								              className={this.state.selected === 4 ? 'nav-link active' : 'nav-link'}
								              onClick={this.handleClick.bind(this, 4)}>
								              {i18next.t("decrypt")}
								            </a>
								        </li>
										: null
									}
							    </ul>
							  </div>  
							</nav>
								<div style={{marginBottom: "10px"}}>
									{navTabs[this.state.selected]}
								</div>
							</div>
					:
					<div id="allTabsContainer">
						<Tabs selected={0}>
							<Pane label={i18next.t("privateKey")}>
								<PrivateKeyContainer availablesCSK={this.state.listCSK} />
							</Pane>
							<Pane label={i18next.t("verifyDs")}>
								<VerifyDSContainer />
							</Pane>
							{
								(this.props.isActiveState.isActive && (this.props.certificateInfo.certInfoSignature || this.props.isActiveState.mobileSignatureCert)) ? <Pane label={i18next.t("createDs")}>
									<CreateDSContainer />
								</Pane> : null
							}
							{
								((this.props.isActiveState.isActive && this.props.certificateInfo.certInfoKeyAgreement) && !this.props.privateKeyReducer.showPinInput) ? <Pane label={i18next.t("encrypt")}>
									<EncryptContainer />
								</Pane> : null
							}
							{
								((this.props.isActiveState.isActive && this.props.certificateInfo.certInfoKeyAgreement) && !this.props.privateKeyReducer.showPinInput) ? <Pane label={i18next.t("decrypt")}>
									<DecryptContainer />
								</Pane> : null
							}
							{
								this.props.base.showKeyGenTab ? <Pane label={i18next.t("keysGeneration")}>
									<KeyGenerator />
								</Pane> : null
							}
							{
								((this.props.isActiveState.isActive && this.props.certificateInfo.certInfoSignature) && this.props.base.vpnKeyGeneratorEnabled === "true") ? <Pane label={i18next.t("vpnKeysTitle")}>
									<VPNKeyGenerator />
								</Pane> : null
							}
						</Tabs>
					</div>
				}
				{
					this.props.base.showErrorPopup
					? <ErrorPopup />
					: null
				}
				</div>
		    );
		} else {
			return (
		        <div>
		        </div>
			);
		}
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        connectionStatus: state.connectionStatusReucer,
        privateKeyReducer: state.privateKeyReducer,
        isActiveState: state.privateKeyReducer,
        certificateInfo: state.certificateInfo,
        mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    getSupportedCA,
	    getConnecteds,
	    setPassiveTokenPath,
	    setActiveTokenPath,
	    getKeysProfiles,
	    setShowKeyGenTab, setIsLoggedIn, setAuthLogin, setAuthPassword, setToken, setTwoFactorEnabledAuth,
		clearTwoFactorEnabledAuth, setTwoFactorCodeAuth, clearTwoFactorCodeAuth, setIs2fEnabled, setCleanUpAll
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Body);