import React, { Component } from 'react';
import i18next from 'i18next'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ServiceConnectionStatus extends Component {

	constructor(props) {
    super(props);

		this.redirectToAgentManual = this.redirectToAgentManual.bind(this)
		this.redirectToManual = this.redirectToManual.bind(this)
	}

	redirectToManual () {
		window.open("https://docs.cipher.kiev.ua/x/bIXxAQ", '_blank');
	}

	redirectToAgentManual () {
		window.open("https://docs.cipher.kiev.ua/x/oIPxAQ", '_blank');
	}

	render() {
		let activeClass = this.props.mobileModeReducer.isMobileDevice ? "d-none d-sm-none d-md-block" : "";
		let mobileStyle = this.props.mobileModeReducer.isMobileDevice ? "col-md-12 col-sm-12 col-xs-12" : "col-md-6 col-sm-12 col-xs-12";
		return (
			<div className="row" style={{textAlign:"center"}}>
					{
						this.props.mobileModeReducer.isMobileDevice
						? null
						: this.props.connectionStatus.uccConnection 
							? <div className="col-md-6 col-sm-12 col-xs-12 margin-top-sm">
								<label>{i18next.t("uccTitle")}</label>
								<div className="serviceUCCConnected" id="serviceConnectionStatus" style={{textAlign:"center"}}>
						        	{i18next.t("connectedTitle")}
						        </div>
							</div> : <div className="col-md-6 col-sm-12 col-xs-12 margin-top-sm">
								<label>{i18next.t("uccTitle")}</label>
								<div className="serviceUCCDisconnected" id="serviceUCCDisconnected" style={{textAlign:"center"}}>
						        	{i18next.t("disconnectedTitle")}
						        </div>
							</div>
					}
					{
						this.props.connectionStatus.cloudConnection
						? <div className={mobileStyle}>
							<div className="col-md-12 col-sm-12 col-xs-12 margin-top-sm">
								<label className={activeClass}>{i18next.t("cloudTitle")}</label>
							</div>
					        <div className="serviceCloudConnected" id="serviceConnectionStatus" style={{textAlign:"center"}}>
					        	{i18next.t("connectedTitle")}
					        </div>
						</div> : <div className={mobileStyle}>
							<div className="col-md-12 col-sm-12 col-xs-12 margin-top-sm">
								<label className={activeClass}>{i18next.t("cloudTitle")}</label>
							</div>
					        <div className="serviceCloudDisconnected" id="serviceCloudDisconnected" style={{textAlign:"center"}}>
					        	{i18next.t("disconnectedTitle")}
					        </div>
						</div>
					}
			</div>
			
	    );
	}
}

function mapStateToProps(state) {
    return {
    	baseUrls: state.base,
    	mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceConnectionStatus);