import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import saveAs from 'file-saver';
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { languageSwitchItem } from '../actions/locales'
import { cipher } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendSessionOption, encryptData,
		    sendKeyContainer, deleteSession, sendTokenPath, sendCertificate, getEncryptedData, getEncryptorStatus, encryptVtco, premission2fVtco, premissionVtco } from '../actions/api'
import { setFilesForEncrypt, setEncryptedFiles, clearFilesForEncrypt, clearEncryptedFiles, setBase64String, setEncryptExpand, setErrorData, setShowErrorPopup } from '../actions/localStates'
import i18next from 'i18next'
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';
import EncryptTextContainer from './EncryptTextContainer'
import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

const dropzoneRef = createRef();

class EncryptFilesContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
      		uuid: null,
      		isLoading: false,
      		downloadIsAvailable: true,
      		files: this.props.encryptDefaultState.fileList || [],
      		encryptedFiles: this.props.encryptDefaultState.encryptedFiles || [],
      		settedToken: null,
      		success: 0,
      		failed: 0,
      		sizeOfFiles: 0,
      		base64Data: null,
      		collapseEncryptShow: "",
      		showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false
	    }

	    this.onChangeFile = this.onChangeFile.bind(this)
	    this.withToken = this.withToken.bind(this)

	    this.handleOnDrop = this.handleOnDrop.bind(this);
	}

	expandEncrypt() {
		this.props.actions.setEncryptExpand(this.props.expandedCardsReducer.encryptExpanded)
		this.props.expandedCardsReducer.encryptExpanded ? this.setState({collapseEncryptShow: "card-body collapse"}) : this.setState({collapseEncryptShow: "card-body collapse show"})
	}

	buildFilesMultipleInputSelector(){
		const filesMultipleInput = document.getElementById('filesMultipleUpload');
		return filesMultipleInput;
	}

	componentDidMount() {
		var dropFile = document.getElementById('dropFile');

		this.filesMultipleInput = this.buildFilesMultipleInputSelector();
		this.props.expandedCardsReducer.encryptExpanded ? this.setState({collapseEncryptShow: "card-body collapse show"}) : this.setState({collapseEncryptShow: "card-body collapse"})

		if (dropFile !== null) {
			dropFile.addEventListener('dragenter', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragover', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragleave', function(event) {
				dropFile.style.border = "";
			}, false);

			dropFile.addEventListener('drop', function(event) {
				dropFile.style.border = "";
			}, false);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.duplicateSign !== this.props.defaultState.duplicateSign) {
			this.filesMultipleInput = this.buildFilesMultipleInputSelector();
		}
	}

	handleFileInput(e) {
	    e.preventDefault();
	    this.filesMultipleInput.value = null;
	    this.filesMultipleInput.click()
	    document.activeElement.blur()
	}

	onChangeFile(e) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (e.target.files[0] !== undefined && e.target.files.length > 0) {

			if (allFilesSize < 104857600) {
				for (var i = 0; i < e.target.files.length; i += 1) {
					if (allFilesSize + (e.target.files[i].size || e.target.files[i].fileSize) < 104857600) {
						fileList.push(e.target.files[i])
						allFilesSize += e.target.files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForEncrypt(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	handleOnDrop (files) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (files[0] !== undefined && files.length > 0) {

			if (allFilesSize < 104857600) {
				for (var i = 0; i < files.length; i += 1) {
					if (allFilesSize + (files[i].size || files[i].fileSize) < 104857600) {
						fileList.push(files[i])
						allFilesSize += files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForEncrypt(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	showError(id) {
		var errorMessage = {
          message: this.state.encryptedFiles[id].message,
          techMessage: this.state.encryptedFiles[id].failureCause
        }
        this.props.actions.setErrorData(errorMessage)
        this.props.actions.setShowErrorPopup(true)

		// alert(this.state.encryptedFiles[id].failureCause)
	}

	deleteItem(id) {
		const files = this.state.files
		const encryptedFiles = this.state.encryptedFiles
		var allFilesSize = this.state.sizeOfFiles

		allFilesSize = allFilesSize - files[id].size
		this.setState({"sizeOfFiles": allFilesSize})

		files.splice(id, 1)
		encryptedFiles.splice(id, 1)
		this.setState({"files": files}, () => {
			this.props.actions.setFilesForEncrypt(this.state.files);
		})
		this.setState({"encryptedFiles": encryptedFiles}, () => {
			this.props.actions.setEncryptedFiles(this.state.encryptedFiles);
		})
	}

	async sendFiles(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

    	await this.props.actions.sendInputData(url, this.state.uuid, file, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.sendCertificate(url, this.state.uuid, this.state.base64Data, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.encryptData(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.getEncryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
    		if (response !== null) {
    			var encFiles = this.state.encryptedFiles
				var success = this.state.success + 1
    			encFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"encryptedFiles": encFiles}
				});
    		}
        })
        await this.props.actions.getEncryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var encFiles = this.state.encryptedFiles
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				encFiles.push(response)
				this.setState(function(){
				    return {"encryptedFiles": encFiles}
				});
        	}
        })
	}

	async sendFilesWithToken(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		await this.props.actions.sendInputData(url, this.state.uuid, file, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.props.defaultState, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.sendCertificate(url, this.state.uuid, this.state.base64Data, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.encryptData(url, this.state.uuid, keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        await this.props.actions.getEncryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
    		if (response !== null) {
    			var encFiles = this.state.encryptedFiles
				var success = this.state.success + 1
    			encFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"encryptedFiles": encFiles}
				});
    		}
        })
        await this.props.actions.getEncryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var encFiles = this.state.encryptedFiles
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				encFiles.push(response)
				this.setState(function(){
				    return {"encryptedFiles": encFiles}
				});
        	}
        })
	}

	cleanUpForm() {
		this.props.actions.clearFilesForEncrypt()
		this.props.actions.clearEncryptedFiles()
		this.setState({"encryptedFiles": []})
		this.setState({"files": []})
	}

	withToken() {
		var _this = this, url, errorMessage;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		this.props.actions.clearEncryptedFiles()
		this.setState({"encryptedFiles": []})
		this.setState({isLoading: true})
		this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
			this.setState({uuid: response.ticketUuid})
			console.log(response);
          	
        })
        .then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput))
        .then((response) => {
        	this.setState({base64Data: response})
            console.log(response)
        })
        .then(async () => {
        	for (var i = 0; i < this.props.encryptDefaultState.fileList.length; i += 1) {
        		await this.sendFilesWithToken(this.props.encryptDefaultState.fileList[i])
			}
        })
		.then(() => this.setState({isLoading: false}))
		.then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		.then(() => this.props.actions.setEncryptedFiles(this.state.encryptedFiles))
		.then(() => {
			if (!_this.props.createDSDefaultState.active) {
				return
			}
			if (_this.state.failed > 0) {
				errorMessage = {
                  message: i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles")
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

				// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles"))
			} else {
				// alert(i18next.t("enc:encSuccessfullyCreated": "encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles": "encFileFiles"))
			}
		})
		.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
	}

	selectEncryptVtco () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.encryptVtco()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.encryptVtco2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	encryptVtco2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})

		data = {
		    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "ENCIPHERMENT",
		    "permissionTtlSeconds" : 10,
		    "permissionRepetition" : 2
		}

		this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		var msg = response.message + "\n" + response.techMessage
	    		alert(msg);
	    	} else {
	    		data = {
				    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "ENCIPHERMENT",
				    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
				}

	    		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    		.then((response) => {
			    	if (response.code !== undefined) {
			    		if (response.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		currentCount = currentCount - 1;

		 					if (currentCount > 0) {
		 						_this.setState({numberOfPinInput: currentCount});
								_this.setState({needToReEnterPin: true});
								_this.setState({showVtcoConfirmPopup: true})
		 					} else {
		 						_this.setState({numberOfPinInput: 0});
		 						_this.setState({needToReEnterPin: false});
								_this.setState({showVtcoConfirmPopup: false})
								_this.setState({isLoading: false})
		 					}

	                	} else {
	                		_this.setState({isLoading: false})
	                		errorMessage = {
		                      message: response.message,
		                      techMessage: response.techMessage
		                    }
		                    _this.props.actions.setErrorData(errorMessage)
		                    _this.props.actions.setShowErrorPopup(true)

				    		// var msg = response.message + "\n" + response.techMessage
				    		// alert(msg);
	                	}
			    	} else {
			    		_this.encryptVtco();
			    	}
			    })
	    	}
	    })
	}

	encryptVtco () {
		var counter = 0, resultDataArray = [], _this = this, certData, sendData, errorMessage;
		this.setState({isLoading: true})

		sendData = function () {
			var jsonObject = {
	        	pin : _this.props.pkLocalState.vtcoPin,
				dataToEncipher : resultDataArray,
				recipientCertificates : [certData]
		    };

		    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

		    var data = {
		        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		        "embedSenderCertificate" : true,
		        "encryptedData": cipherResult.encryptedData,
		        "secretKey": cipherResult.secretKey
		    }

		    _this.props.actions.encryptVtco(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
		    .then((response) => {
		    	if (response.code !== undefined) {
		    		_this.setState({isLoading: false})
		    		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)

		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
		    	} else {
		    		_this.setState({"encryptedFiles": response});
			    	_this.props.actions.setEncryptedFiles(response)
			    	_this.setState({isLoading: false})
		    	}
		    })
		}

		function readFile(file) {
		  return new Promise((resolve, reject) => {
		    const reader = new FileReader();

		    reader.onload = res => {
		    var base64String = window.btoa(res.target.result);
		      resolve(base64String);
		    };
		    reader.onerror = err => reject(err);

		    reader.readAsBinaryString(file);
		  });
		}

    	async function read(file) {
			resultDataArray.push(await readFile(file));
			counter += 1;

            if (counter === _this.props.encryptDefaultState.fileList.length) {
            	sendData()
            }
		}

		function readFiles () {
			for (var i = 0; i < _this.props.encryptDefaultState.fileList.length; i += 1) {
	            read(_this.props.encryptDefaultState.fileList[i]);
			}
		}

		var reader = new FileReader();
        reader.readAsBinaryString(_this.props.defaultEncrypState.fileCertInput);
        reader.onload = function (evt) {
            var binaryData = evt.target.result;
            var base64String = window.btoa(binaryData);

            certData = base64String;
            readFiles();

        }
        reader.onerror = function (evt) {
            document.getElementById("fileContents").innerHTML = "error reading file";
        }
	}

	encryptMultiple() {
		var _this = this, url, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			this.selectEncryptVtco();
			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
	            let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.withToken()
	        } else {
	        	this.props.actions.clearEncryptedFiles()
				this.setState({"encryptedFiles": []})
				this.setState({isLoading: true})
				this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		        .then((response) => {
					this.setState({uuid: response.ticketUuid})
					console.log(response);
		          	
		        })
		        .then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput))
	            .then((response) => {
	            	this.setState({base64Data: response})
                    console.log(response)
                })
		        .then(async () => {
		        	for (var i = 0; i < this.state.files.length; i += 1) {
		        		await this.sendFiles(this.state.files[i])
					}
		        })
				.then(() => this.setState({isLoading: false}))
				.then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
				.then(() => this.props.actions.setEncryptedFiles(this.state.encryptedFiles))
				.then(() => {
					if (!_this.props.createDSDefaultState.active) {
						return
					}
					if (_this.state.failed > 0) {
						errorMessage = {
	                      message: i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles")
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)

						// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles"))
					} else {
						// alert(i18next.t("enc:encSuccessfullyCreated": "encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles": "encFileFiles"))
					}
				})
				.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
	        }
	    } else {
	    	this.setState({"settedToken": settedToken})
	    	this.withToken()
	    }
	}

    getBlobData(id) {
    	var blob;
    	const b64toBlob = (b64Data, contentType, sliceSize=512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

	    if (this.props.encryptDefaultState.fileList[id].name.length > 0) {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.encryptDefaultState.encryptedFiles[id], {type : 'p7e'});
	    		saveAs(blob, this.props.encryptDefaultState.fileList[id].name + ".p7e")
	    	} else {
	    		saveAs(this.props.encryptDefaultState.encryptedFiles[id], this.props.encryptDefaultState.fileList[id].name + ".p7e");
	    	}
	    }
	    
	}

	render() {
		const isEnabled = (this.props.defaultEncrypState.fileCertInput && this.props.encryptDefaultState.fileList.length > 0 && !this.state.isLoading) 
			? true
			: false

		return (
			<div>
            <div className="card" style={{"marginBottom": "10px"}}>
				<div className="card-header" onClick={this.expandEncrypt.bind(this)} role="button">
					{
						this.props.expandedCardsReducer.encryptExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
					}&nbsp;{i18next.t("file")}
				</div>
              	<div className={this.state.collapseEncryptShow}>
                    <div>
                    <div className="row">
			            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 order-1">
			            	<ul className="add-files" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none", "padding": "0", "minHeight": "200px"}}>
			            		{
			            			this.props.encryptDefaultState.fileList.map((n, index) => {
			            				var active = index % 2 === 0 ? "evenClassName" : "oddClassName"
									    return (<li key={index} className={active} style={{"padding": "5px"}}>
									    	<span className="row">
									    		<span className={(this.state.isLoading || this.state.encryptedFiles[index]) ? "col-md-9 col-sm-9 col-xs-9" : "col-md-10  col-sm-10 col-xs-10"} style={{"wordWrap": "break-word", "display": "block"}}>{n.name}</span>
									    		<span className="col-md-1 col-sm-1 col-xs-1" onClick={this.deleteItem.bind(this, index)}><i className="fa fa-trash" style={{"cursor": "pointer"}} title={i18next.t("deleteTitle")} aria-hidden="true"></i></span>
										    	<span className="col-md-1 col-sm-1 col-xs-1">
										    	{
										    		(this.state.encryptedFiles[index] !== undefined && this.state.encryptedFiles[index].failureCause)
										    			? <i className="fa fa-exclamation-triangle" title={i18next.t("errorTitle")} style={{"cursor": "pointer"}} onClick={this.showError.bind(this, index)}></i>
										    			: this.state.encryptedFiles[index]
										    			? <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer"}} onClick={this.getBlobData.bind(this, index)}></i>
										    			: null
										    	}
										    	{
										    		this.state.isLoading && !this.state.encryptedFiles[index]
										    			? <i className="fa fa-spinner fa-pulse"></i>
										    			: null
										    	}
										    	</span>
										    </span>
									    	</li>)
									})
			            		}
			            	</ul>
			            </div>
			            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 no-left-padding order-0 order-lg-12" style={{"marginBottom": "10px"}}>
			            	{
			            		this.props.mobileModeReducer.isMobileDevice
			            		? <button className="btn btn-default btn-block" disabled={this.state.isLoading} onClick={this.handleFileInput.bind(this)}>{i18next.t("dsAddFileFilesTitle")}</button>
			            		: <Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop}>
			                        {({getRootProps, getInputProps}) => (
			                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone-md" id="dropFile">
			                                <input {...getInputProps()} />
			                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitle")}</p>
			                            </div>
			                        )}
			                    </Dropzone>
			            	}
			            </div>
			            <input id="filesMultipleUpload" type="file" onChange={this.onChangeFile.bind(this)} style={{"display": "none"}} multiple/>
		            </div>
	            	<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
	                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.encryptMultiple.bind(this)} disabled={!isEnabled}>{i18next.t("enc:performDataEncryption")}
	                        	{
	                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
	                                : null
	                            }
	                        </button>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-left-padding">
                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <EncryptTextContainer />
            {
				this.state.showVtcoConfirmPopup
				? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"encryptConfirm"} tries={this.state.numberOfPinInput} />
				: null
			}
            </div>
      )
	}
}


function mapStateToProps(state) {
    return {
    	base: state.base,
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        createDSDefaultState: state.createDSInputReducer,
        defaultState: state.encryptOptionsReducer,
        encryptDefaultState: state.encryptFilesReducer,
        connectionStatus: state.connectionStatusReucer,
        defaultEncrypState: state.encryptReducer,
        expandedCardsReducer: state.expandedCardsReducer,
        mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    setFilesForEncrypt,
	    setEncryptedFiles,
	    clearFilesForEncrypt,
	    setBase64String,
	    clearEncryptedFiles,
	    createSession, sendInputData, sendSessionOption, encryptData,
		sendKeyContainer, deleteSession, sendTokenPath, sendCertificate, getEncryptedData,
		getEncryptorStatus,
		setEncryptExpand, cipher, encryptVtco,
		premission2fVtco, premissionVtco,
		setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EncryptFilesContainer);
