import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class Tabs extends Component {
    constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected
    }
  }
  getDefaultProps() {
    return {
      selected: 0
    };
  }
  getInitialState() {
    return {
      selected: this.props.selected
    };
  }
  handleClick(index, event) {
    if (this.props.base.globalProcessing) {
      return;
    }
    event.preventDefault();
    this.setState({
      selected: index
    });
  }
  propTypes: {
    selected: number,
    children: array,
    children: element
  }
  _renderTitles() {
    function labels(child, index) {
      let activeClass = (this.state.selected === index ? 'nav-link active' : 'nav-link');
      return (
        child !== null ?
          <li key={index} className="nav-item">
            <a href={null} style={{"cursor": "pointer", color: "#888"}}
              className={activeClass}
              onClick={this.handleClick.bind(this, index)}>
              {child.props.label}
            </a>
          </li>
        : null
      );
    }
    return (
      <ul className="nav nav-tabs">
        {this.props.children.map(labels.bind(this))}
      </ul>
    );
  }
  _renderContent() {
    return (
      <div>
        {
          this.props.children[this.state.selected] !== null
            ? this.props.children[this.state.selected]
            : this.setState({
                selected: 0
              })
        }
      </div>
    );
  }
  render() {
    return (
      <div>
        {this._renderTitles()}
        {this._renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
      base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Tabs);