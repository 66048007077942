import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { createSession, sendEncryptTextData, sendCertificate, sendSessionOption, encryptTextData,
		    sendKeyContainer, getEncryptTextData, deleteSession, sendTokenPath, encryptData, getEncryptorStatus, encryptVtco, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputTextForEncrypt, setInputTextForEncryptUintArr, clearInputTextForEncryptUintArr, clearInputTextForEncrypt, setEncryptEncodingType,
			setEncryptDataBase64, clearEncryptDataBase64, setBase64String, setEncryptTextExpand, setErrorData, setShowErrorPopup } from '../actions/localStates'
import { StringToUTF16LEArray, fromByteArray, StringToArray, toByteArray, cipher } from '../actions/stringEncodersFuncs'
import i18next from 'i18next'

import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'


class EncryptTextContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
      		uuid: null,
      		isLoading: false,
      		blobData: null,
      		inputTextValue: "",
      		responseTextValue: "",
      		base64Data: null,
      		collapseEncryptTextShow: "",
      		showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false
	    }

	    this.withToken = this.withToken.bind(this)
	    this.textEncodingHendler = this.textEncodingHendler.bind(this)
	    this.textInputHendler = this.textInputHendler.bind(this)

	    this.copyToClipboard = this.copyToClipboard.bind(this)
	}

	componentDidMount() {
		this.props.expandedCardsReducer.encryptTextExpanded ? this.setState({collapseEncryptTextShow: "card-body collapse show"}) : this.setState({collapseEncryptTextShow: "card-body collapse"})
	}

	expandEncryptText() {
		this.props.actions.setEncryptTextExpand(this.props.expandedCardsReducer.encryptTextExpanded)
		this.props.expandedCardsReducer.encryptTextExpanded ? this.setState({collapseEncryptTextShow: "card-body collapse"}) : this.setState({collapseEncryptTextShow: "card-body collapse show"})
	}

	textEncodingHendler(e) {
		this.props.actions.setEncryptEncodingType(e.target.value)
	}

	textInputHendler(e) {
		this.props.actions.setInputTextForEncrypt(e.target.value)
		this.props.actions.clearEncryptDataBase64()
	}

	cleanUpForm() {
		this.setState({inputTextValue: ""})
		this.setState({responseTextValue: ""})
		this.props.actions.clearInputTextForEncryptUintArr()
		this.props.actions.clearInputTextForEncrypt()
		this.props.actions.clearEncryptDataBase64()
	}

	copyToClipboard () {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(this.props.defaultState.textDataInBase64);
	}

	withToken(settedToken) {
		var arr, url, _this = this, errorMessage;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		this.props.defaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.defaultState.textForEncrypt, false)
			: arr = this.props.actions.StringToArray(this.props.defaultState.textForEncrypt, true)

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForEncryptUintArr(u8Arr)

		this.setState({isLoading: true})
		this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        .then((response) => {
         	this.setState({uuid: response.ticketUuid})
          	console.log(response);
			return this.props.actions.sendEncryptTextData(url, this.state.uuid, this.props.defaultState.textForEncryptUint8Arr, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
				.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.optionsReducer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
				.then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then((response) => {
	            	this.setState({base64Data: response})
                    console.log(response);
				return this.props.actions.sendCertificate(url, this.state.uuid, this.state.base64Data, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
	            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.encryptData(url, this.state.uuid, keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.encryptTextData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.getEncryptTextData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then((response) => {
	            		if (response !== null) {
	            			this.props.actions.setEncryptDataBase64(response.base64Data)
	            		}
	            		this.setState({isLoading: false})
	                  })
	            .then(() => this.props.actions.getEncryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
			        .then((response) => {
			        	if (response.failureCause !== undefined && response.failureCause.length > 0) {
			        		// alert(response.failureCause)
			        		errorMessage = {
	                          message: response.message,
	                          techMessage: response.failureCause
	                        }
	                        this.props.actions.setErrorData(errorMessage)
	                        this.props.actions.setShowErrorPopup(true)

			        	} else {
			        		// alert(i18next.t("enc:encTextSuccessfullyCreated": "encTextSuccessfullyCreated"))
			        	}
			        	console.log(response)
			    	})
			    )
	            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			})
	    })
	}

	selectEncryptVtco () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.encryptVtco()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.encryptVtco2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	encryptVtco2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})

		data = {
		    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "ENCIPHERMENT",
		    "permissionTtlSeconds" : 10,
		    "permissionRepetition" : 2
		}

		this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		errorMessage = {
                  message: response.message,
                  techMessage: response.techMessage
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)

	    		// var msg = response.message + "\n" + response.techMessage
	    		// alert(msg);
	    	} else {
	    		data = {
				    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "ENCIPHERMENT",
				    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
				}

	    		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    		.then((response) => {
			    	if (response.code !== undefined) {
			    		if (response.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		currentCount = currentCount - 1;

		 					if (currentCount > 0) {
		 						_this.setState({numberOfPinInput: currentCount});
								_this.setState({needToReEnterPin: true});
								_this.setState({showVtcoConfirmPopup: true})
		 					} else {
		 						_this.setState({numberOfPinInput: 0});
		 						_this.setState({needToReEnterPin: false});
								_this.setState({showVtcoConfirmPopup: false})
								_this.setState({isLoading: false})
		 					}

	                	} else {
	                		_this.setState({isLoading: false})
	                		errorMessage = {
			                  message: response.message,
			                  techMessage: response.techMessage
			                }
			                _this.props.actions.setErrorData(errorMessage)
			                _this.props.actions.setShowErrorPopup(true)
				    		// var msg = response.message + "\n" + response.techMessage
				    		// alert(msg);
	                	}
			    	} else {
			    		_this.encryptVtco();
			    	}
			    })
	    	}
	    })
	}

	encryptVtco () {
		var _this = this, sendData, certData, errorMessage;
		this.setState({isLoading: true})

		var encodedString = btoa(this.props.defaultState.textForEncrypt);

		sendData = function () {
			var jsonObject = {
	        	pin : _this.props.pkLocalState.vtcoPin,
				dataToEncipher : [
					encodedString
				]
		    };

		    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

		    var data = {
		        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		        "recipientCertificates" : [certData],
		        "embedSenderCertificate" : true,
		        "encryptedData": cipherResult.encryptedData,
		        "secretKey": cipherResult.secretKey
		    }

		    _this.props.actions.encryptVtco(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
		    .then((response) => {
		    	if (response.code !== undefined) {
		    		_this.setState({isLoading: false})
		    		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)

		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
		    	} else {
		    		_this.props.actions.setEncryptDataBase64(response[0])
			    	console.log(response)
			    	_this.setState({isLoading: false})
		    	}
		    })
		}

		var reader = new FileReader();
        reader.readAsBinaryString(_this.props.defaultEncrypState.fileCertInput);
        reader.onload = function (evt) {
            var binaryData = evt.target.result;
            var base64String = window.btoa(binaryData);

            certData = base64String;
            sendData();

        }
        reader.onerror = function (evt) {
            document.getElementById("fileContents").innerHTML = "error reading file";
        }
	}

	encryptTextData() {
		var url, arr, _this = this, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			this.selectEncryptVtco();
			return;
		}
		
		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		this.props.defaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.defaultState.textForEncrypt, false)
			: arr = this.props.actions.StringToArray(this.props.defaultState.textForEncrypt, true)

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForEncryptUintArr(u8Arr)

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				settedToken = {"keyStorePath": this.props.pkLocalState.keyContainer}
				this.withToken(settedToken)
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		        .then((response) => {
		         	this.setState({uuid: response.ticketUuid})
		          	console.log(response);
					return this.props.actions.sendEncryptTextData(url, this.state.uuid, this.props.defaultState.textForEncryptUint8Arr, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
						.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.props.optionsReducer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
						.then(() => this.props.actions.setBase64String(this.props.defaultEncrypState.fileCertInput, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then((response) => {
			            	this.setState({base64Data: response})
		                    console.log(response);
						return this.props.actions.sendCertificate(url, this.state.uuid, this.state.base64Data, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
			            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.encryptData(url, this.state.uuid, keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.encryptTextData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.getEncryptTextData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then((response) => {
			            		if (response !== null) {
			            			this.props.actions.setEncryptDataBase64(response.base64Data)
			            		}
			            		this.setState({isLoading: false})
			                  })
			            .then(() => this.props.actions.getEncryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
					        .then((response) => {
					        	if (response.failureCause !== undefined && response.failureCause.length > 0) {
					        		errorMessage = {
				                      message: response.message,
				                      techMessage: response.failureCause
				                    }
				                    _this.props.actions.setErrorData(errorMessage)
				                    _this.props.actions.setShowErrorPopup(true)

					        		// alert(response.failureCause)
					        	} else {
					        		// alert(i18next.t("enc:encTextSuccessfullyCreated": "encTextSuccessfullyCreated"))
					        	}
					        	console.log(response)
					    	})
					    )
			            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
					})
			    })
		    }
		} else {
			this.withToken(settedToken)
		}
	}

	render() {
		const isTextDSEnabled = (this.props.defaultEncrypState.fileCertInput && this.props.defaultState.textForEncrypt.length > 0 && !this.state.isLoading) 
			? true
			: false

		const isTextDSCopyEnabled = this.props.defaultState.textDataInBase64

		return (
			<div>
				<div className="card">
					<div className="card-header" onClick={this.expandEncryptText.bind(this)} role="button">
						{
						this.props.expandedCardsReducer.encryptTextExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						}&nbsp;{i18next.t("textData")}
					</div>
					<div className="collapse show">
	                  	<div className={this.state.collapseEncryptTextShow}>
		                    <div>
		                    	<div className="form">
		                    		<label>{i18next.t("encoding")}</label>
		                    		<div className="form-check-inline">
			                            <label className="form-check-label" style={{marginLeft: "7px"}}>
			                                <input className="form-check-input" value="UTF-16LE" type="radio" checked={this.props.defaultState.textEncoding === 'UTF-16LE'} onChange={this.textEncodingHendler}/>
			                                <span className="form-check-label">{i18next.t("utf16LeEncoding")}</span>
			                            </label>
			                            <label className="form-check-label" style={{marginLeft: "7px"}}>
			                                <input className="form-check-input" value="UTF-8" type="radio" checked={this.props.defaultState.textEncoding === 'UTF-8'} onChange={this.textEncodingHendler} />
			                                <span className="form-check-label">{i18next.t("utf8Encoding")}</span>
			                            </label>
				                    </div>
		                    	</div>
								<div>
		                    		<label>{i18next.t("enc:textForEncryptTitle")}</label>
			                        <div className="input-group mb-4">
										<textarea id="dscTextForDs" rows="5" className="form-control" value={this.props.defaultState.textForEncrypt} onInput={this.textInputHendler}></textarea>
									</div>
		                    	</div>
		                    	<div className="row">
									<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
				                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.encryptTextData.bind(this)} disabled={!isTextDSEnabled}>{i18next.t("enc:performDataEncryption")}
				                        	{
				                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
				                                : null
				                            }
				                        </button>
			                        </div>
			                        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-left-padding">
			                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
			                        </div>
			                    </div>
		                       
		                        <div style={{"paddingTop": "15px"}}>
									<label>{i18next.t("enc:encryptedTextBase64Title")}</label>
									<textarea id="dscDsBase64" rows="5" className="form-control" value={this.props.defaultState.textDataInBase64} readOnly></textarea>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 no-right-padding" style={{"paddingTop": "10px", "paddingLeft": "0px"}}>
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isTextDSCopyEnabled} onClick={this.copyToClipboard.bind(this)}>{i18next.t("copyToClipboardTitle")}</button>
		                        </div>
		                    </div>
	                    </div>
	                </div>
                </div>
                {
					this.state.showVtcoConfirmPopup
					? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"encryptConfirm"} tries={this.state.numberOfPinInput} />
					: null
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        connectionStatus: state.connectionStatusReucer,
        defaultState: state.encryptTextReducer,
        optionsReducer: state.encryptOptionsReducer,
        defaultEncrypState: state.encryptReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    createSession,
		sendEncryptTextData,
		sendCertificate,
		sendSessionOption,
		encryptTextData,
		sendKeyContainer,
		setBase64String,
		encryptData,
		getEncryptTextData,
		deleteSession,
		setInputTextForEncrypt,
		setInputTextForEncryptUintArr,
		clearInputTextForEncryptUintArr,
		clearInputTextForEncrypt,
		setEncryptEncodingType,
		setEncryptDataBase64,
		clearEncryptDataBase64,
		sendTokenPath,
		StringToUTF16LEArray,
		fromByteArray,
		StringToArray,
		toByteArray,
		setEncryptTextExpand,
		getEncryptorStatus, encryptVtco, cipher,
		premission2fVtco, premissionVtco,
		setErrorData, setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EncryptTextContainer);