
const verifyDSDafaultState = {
    textInput: "",
    textEncoding: "UTF-16LE",
    encodedText: "",
    fileSignedData: null,
	fileInputName: "",
	fileWithDSName: "",
	fileInputSelected: false,
	fileWithDSSelected: false,
    verifyResultsText: null,
    divId: "",
    textDS: "",
    dsDataInBase64: "",
    differenceSignatureType: true
}

const verifyDSTextInputReducer = (state = verifyDSDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_VERIFY_INPUT_TEXT": {
		    return {
		        ...state,
		        textInput: payload,
		        textInputSelected: true,
		    }
		}

        case "CLEAR_VERIFY_INPUT_TEXT": {
            return {
                ...state,
                textInput: "",
                textInputSelected: false,
            }
        }

        case 'SET_VERIFY_DS_ENCODING_TYPE': {
            return {
                ...state,
                textEncoding: payload
            }
        }

        case "SET_INPUT_TEXT_FOR_VERIFY_DS": {
            return {
                ...state,
                encodedText: payload
            }
        }

        case "SET_SIGNED_TEXT_DATA": {
            return {
                ...state,
                dsDataInBase64: payload
            }
        }

        case "CLEAR_SIGNED_TEXT_DATA": {
            return {
                ...state,
                dsDataInBase64: ""
            }
        }

        case "SET_INPUT_TEXT_DS_FOR_VERIFY": {
            return {
                ...state,
                textDS: payload
            }
        }

        case "CLEAR_INPUT_TEXT_DS_FOR_VERIFY": {
            return {
                ...state,
                textDS: ""
            }
        }

        case "SET_FILE_SIGNED_DATA": {
            return {
                ...state,
                fileSignedData: payload
            }
        }

        case "CLEAR_FILE_SIGNED_DATA": {
            return {
                ...state,
                fileSignedData: null
            }
        }

        case "SET_VERIFY_RESULT_TEXT": {
            return {
                ...state,
                verifyResultsText: payload.data,
                divId: payload.div
            }
        }

        case "CLEAR_VERIFY_RESULT_TEXT": {
            return {
                ...state,
                verifyResultsText: null,
                divId: ""
            }
        }

		case "CLEAR_VERIFY_INPUT_FILE": {
		    return {
		        ...state,
		        fileInput: null,
		        fileInputName: "",
		        fileInputSelected: false,
		    }
		}

        case 'SET_VERIFY_DS_INPUT_FILE': {
            return {
                ...state,
                fileWithDS: payload,
                fileWithDSName: payload.name,
                fileWithDSSelected: true
            }
        }

        case 'CLEAR_VERIFY_DS_INPUT_FILE': {
            return {
                ...state,
                fileWithDS: null,
                fileWithDSName: "",
                fileWithDSSelected: false
            }
        }

        case 'SET_SIGNATURE_DIFFERENCE_TYPE': {
            return {
                ...state,
                differenceSignatureType: payload
            }
        }

        default:
            return state;
    }

}

export default verifyDSTextInputReducer