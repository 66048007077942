
const dafaultState = {
	verifyOptionsExpanded: true,
	verifyExpanded: true,
	verifyTextExpanded: false,
	createOptionsExpanded: true,
	createExpanded: true,
	createTextExpanded: false,
	encryptOptionsExpanded: true,
	encryptExpanded: true,
	encryptTextExpanded: false,
	encryptCertExpanded: false,
	decryptExpanded: true,
	decryptTextExpanded: false,
	actionsExpanded: true,
	createDSAddToExistExpanded: true

}

export default function (state=dafaultState, { type, payload }) {
	switch(type) {
		case "SET_VERIFY_DS_OPTIONS_EXPAND": {
			return {
				...state,
				verifyOptionsExpanded: payload
			}
		}

		case "SET_VERIFY_DS_EXPAND": {
			return {
				...state,
				verifyExpanded: payload
			}
		}

		case "SET_VERIFY_DS_TEXT_EXPAND": {
			return {
				...state,
				verifyTextExpanded: payload
			}
		}

		case "SET_CREATE_DS_OPTIONS_EXPAND": {
			return {
				...state,
				createOptionsExpanded: payload
			}
		}

		case "SET_CREATE_DS_EXPAND": {
			return {
				...state,
				createExpanded: payload
			}
		}

		case "SET_CREATE_DS_TEXT_EXPAND": {
			return {
				...state,
				createTextExpanded: payload
			}
		}

		case "SET_ENCRYPT_OPTIONS_EXPAND": {
			return {
				...state,
				encryptOptionsExpanded: payload
			}
		}

		case "SET_ENCRYPT_EXPAND": {
			return {
				...state,
				encryptExpanded: payload
			}
		}

		case "SET_ENCRYPT_TEXT_EXPAND": {
			return {
				...state,
				encryptTextExpanded: payload
			}
		}

		case "SET_ENCRYPT_CERT_EXPAND": {
			return {
				...state,
				encryptCertExpanded: payload
			}
		}

		case "SET_DECRYP_EXPAND": {
			return {
				...state,
				decryptExpanded: payload
			}
		}

		case "SET_DECRYP_TEXT_EXPAND": {
			return {
				...state,
				decryptTextExpanded: payload
			}
		}

		case "SET_ACTIONS_EXPAND": {
			return {
				...state,
				actionsExpanded: payload
			}
		}

		case "SET_CREATE_DS_ADD_TO_EXIST_EXPAND": {
			return {
				...state,
				createDSAddToExistExpanded: payload
			}
		}

		default:
			return state;
	}
}