
const dafaultState = {
    certInfoSignature: null,
    certInfoKeyAgreement: null,
    certSignature: null,
    certKeyAgreement: null
}

export default function (state=dafaultState, { type, payload }) {
	switch(type) {
		case "CERTIFICATE_INFO": {
			return {
				...state,
				certInfoSignature: payload
			}
		}

		case "CLEAR_CERTIFICATE_INFO": {
			return {
				...state,
				certInfoSignature: payload
			}
		}

		case "CERTIFICATE_INFO_KEY_AGREEMENT": {
			return {
				...state,
				certInfoKeyAgreement: payload
			}
		}

		case "CLEAR_CERTIFICATE_INFO_KEY_AGREEMENT": {
			return {
			    ...state,
				certInfoKeyAgreement: payload
			}
		}

		case "SET_SIGNATURE_CERTIFICATE": {
			return {
				...state,
				certSignature: payload
			}
		}

		case "SET_KEY_AGREEMENT_CERTIFICATE": {
			return {
				...state,
				certKeyAgreement: payload
			}
		}

		default:
			return state;
	}
}