
const dafaultState = {
    isMobileDevice: false,
    mobileDeviceMode: false
}

export default function (state=dafaultState, { type, payload }) {
	switch(type) {
		case "SET_IS_MOBILE_DEVICE": {
			return {
				...state,
				isMobileDevice: payload
			}
		}

		case "SET_MOBILE_DEVICE_MODE": {
			return {
				...state,
				mobileDeviceMode: payload
			}
		}

		default:
			return state;
	}
}