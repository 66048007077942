
const upgradeDSDefaultState = {
    fileWithDS: null,
    fileWithDSName: "",
    fileWithDSSelected: false,
    baseFile: null,
    baseFileName: "",
    baseFileSelected: false,
    modifiedDSData: null
}

const upgradeDSReducer = (state = upgradeDSDefaultState, { type, payload }) => {

    switch (type) {
        case "SET_FILE_WITH_DS_FOR_UPGARDE": {
            return {
                ...state,
                fileWithDS: payload,
                fileWithDSName: payload.name,
                fileWithDSSelected : true
            }
        }

        case "SET_BASE_FILE_FOR_UPGRADE": {
            return {
                ...state,
                baseFile: payload,
                baseFileName: payload.name,
                baseFileSelected : true
            }
        }

        case "CLEAR_FILE_WITH_DS_FOR_UPGRADE": {
            return {
                ...state,
                fileWithDS: null,
                fileWithDSName: "",
                fileWithDSSelected: false
            }
        }

        case "CLEAR_BASE_FILE_FOR_UPGRADE": {
            return {
                ...state,
                baseFile: null,
                baseFileName: "",
                baseFileSelected: false
            }
        }

        case "SET_MODIFIED_DS_DATA": {
            return {
                ...state,
                modifiedDSData: payload
            }
        }

        case "CLEAR_MODIFIED_DS_DATA": {
            return {
                ...state,
                modifiedDSData: null
            }
        }

        default:
            return state;
    }

}

export default upgradeDSReducer