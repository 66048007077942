import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { expandDS, expandTSVerify, expandDataVerifyTs, setSignatureType, setTsCheck, setTsVerifyType, setVerifyDSOptionsExpand, setShowUpgradeCard,
			clearVerifyResultData, clearModifiedDSData, clearFileSignedData, setGenerateQR, clearVerifyResultText, clearTextSignedData,
			clearUpgradedDSInBase64 } from '../actions/localStates'

class VerifyDSOptionsContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			collapseDSTypeShow: "",
			collapseTSVerifyShow: "",
			collapseDataVerifyTsShow: "",
			collapseVerifyDSOptionsShow: "",
			showUpgradeDSCard: true
	    }

	    this.dsTypeHendler = this.dsTypeHendler.bind(this)
	    this.tsVerifyHendler = this.tsVerifyHendler.bind(this)
	    this.tsVerifyTypeHendler = this.tsVerifyTypeHendler.bind(this)
	    this.showUpgradeCardHandler = this.showUpgradeCardHandler.bind(this)
	    this.setGenerateQRHandler = this.setGenerateQRHandler.bind(this)
	}

	expandVerifyDSOptions() {
		this.props.actions.setVerifyDSOptionsExpand(this.props.expandedCardsReducer.verifyOptionsExpanded)
		this.props.expandedCardsReducer.verifyOptionsExpanded ? this.setState({collapseVerifyDSOptionsShow: "card-body collapse"}) : this.setState({collapseVerifyDSOptionsShow: "card-body collapse show"})
	}

	expandDS() {
		this.props.actions.expandDS(this.props.defaultState.expandDSType)
		this.props.defaultState.expandDSType ? this.setState({collapseDSTypeShow: "collapse"}) : this.setState({collapseDSTypeShow: "collapse show"})
	}

	expandTSVerify() {
		this.props.actions.expandTSVerify(this.props.defaultState.expandTSVerify)
		this.props.defaultState.expandTSVerify ? this.setState({collapseTSVerifyShow: "collapse"}) : this.setState({collapseTSVerifyShow: "collapse show"})
	}

	expandDataVerifyTs() {
		this.props.actions.expandDataVerifyTs(this.props.defaultState.expandDataVerifyTs)
		this.props.defaultState.expandDataVerifyTs ? this.setState({collapseDataVerifyTsShow: "collapse"}) : this.setState({collapseDataVerifyTsShow: "collapse show"})
	}

	componentDidMount() {
		this.props.defaultState.expandDSType ? this.setState({collapseDSTypeShow: "collapse show"}) : this.setState({collapseDSTypeShow: "collapse"})
		this.props.defaultState.expandTSVerify ? this.setState({collapseTSVerifyShow: "collapse show"}) : this.setState({collapseTSVerifyShow: "collapse"})
		this.props.defaultState.expandDataVerifyTs ? this.setState({collapseDataVerifyTsShow: "collapse show"}) : this.setState({collapseDataVerifyTsShow: "collapse"})
		this.props.expandedCardsReducer.verifyOptionsExpanded ? this.setState({collapseVerifyDSOptionsShow: "card-body collapse show"}) : this.setState({collapseVerifyDSOptionsShow: "card-body collapse"})
	}

	dsTypeHendler(e) {
		this.props.actions.setSignatureType(e.target.value)
	}

	tsVerifyHendler(e) {
		this.props.actions.setTsCheck(e.target.value)
	}

	tsVerifyTypeHendler(e) {
		this.props.actions.setTsVerifyType(e.target.value)
	}

	showUpgradeCardHandler(e) {
		this.props.actions.setShowUpgradeCard(e.target.checked)
		this.props.actions.clearVerifyResultData()
	    this.props.actions.clearModifiedDSData()
	    this.props.actions.clearFileSignedData()
	    this.props.actions.clearVerifyResultText()
    	this.props.actions.clearTextSignedData()
    	this.props.actions.clearUpgradedDSInBase64()
	}

	setGenerateQRHandler (e) {
		this.props.actions.setGenerateQR(e.target.checked)
		this.props.actions.clearVerifyResultData()
	    this.props.actions.clearModifiedDSData()
	    this.props.actions.clearFileSignedData()
	    this.props.actions.clearVerifyResultText()
    	this.props.actions.clearTextSignedData()
    	this.props.actions.clearUpgradedDSInBase64()
	}

	render() {
		return (
			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{paddingBottom: "10px"}}>
				<div className="card">
	            <div className="card-header" onClick={this.expandVerifyDSOptions.bind(this)}>
					{i18next.t("ds:dsVerifyParamsTitle")}
	            </div>
	            <div id="dsvLeftParamArea" className={this.state.collapseVerifyDSOptionsShow} data-toggle="collapse" aria-expanded={this.props.expandedCardsReducer.verifyOptionsExpanded}>
	                <div>
	                    <label style={{"cursor": "pointer"}} data-toggle="collapse" onClick={this.expandDS.bind(this)} role="button" aria-expanded={this.props.defaultState.expandDSType} aria-controls="dsvLeftParamAreaDsType">
	                        {
								this.props.defaultState.expandDSType ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
									: <i className="fa fa-chevron-right" aria-hidden="true"></i>
							}
	                        &nbsp;
	                        <span id="dsvDsTypeTitle">{i18next.t("ds:dsTypeTitle")}</span>
	                    </label>
	                    <div id="dsvLeftParamAreaDsType" className={this.state.collapseDSTypeShow}>
	                        <div className="form-check">
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvAttachedDsLabel" className="grey-tooltip" title={i18next.t("ds:attachedDsTooltip")}>
	                                    <input className="form-check-input" name="dsvSignatureType" value="attached" type="radio" checked={this.props.defaultState.signatureType === 'attached'} onChange={this.dsTypeHendler} />
	                                    <span className="form-check-label" id="dsvAttachedDsTitle">{i18next.t("ds:attachedDsTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvDetachedDsLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:detachedDsTooltip")}>
	                                    <input className="form-check-input" name="dsvSignatureType" value="detached" type="radio" checked={this.props.defaultState.signatureType === 'detached'} onChange={this.dsTypeHendler} />
	                                    <span className="form-check-label" id="dsvDetachedDsTitle">{i18next.t("ds:detachedDsTitle")}</span>
	                                </label>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <div>
	                    <label style={{"cursor": "pointer"}} data-toggle="collapse" href={null} onClick={this.expandTSVerify.bind(this)} aria-expanded={this.props.defaultState.expandTSVerify} aria-controls="dsvLeftParamAreaDsTsCheck" role="button">
	                        {
								this.props.defaultState.expandTSVerify ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
									: <i className="fa fa-chevron-right" aria-hidden="true"></i>
							}
	                        &nbsp;
	                        <span id="dsvTsVerifyMode">{i18next.t("ds:dsTsVerifyMode")}</span>
	                    </label>
	                    <div id="dsvLeftParamAreaDsTsCheck" className={this.state.collapseTSVerifyShow}>
	                        <div className="form-check">
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvIgnoreTsLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dsIgnoreTsTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionDs" value="ignore" type="radio" checked={this.props.defaultState.signatureTsVerifyOption === 'ignore'} onChange={this.tsVerifyHendler} />
	                                    <span className="form-check-label" id="dsvIgnoreTsTitle">{i18next.t("ds:ignoreTsTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvVerifyTsIfPresentLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dsVerifyTsIfPresentTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionDs" value="verifyIfPresent" type="radio" checked={this.props.defaultState.signatureTsVerifyOption === 'verifyIfPresent'} onChange={this.tsVerifyHendler} />
	                                    <span className="form-check-label" id="dsvVerifyTsIfPresentTitle">{i18next.t("ds:verifyTsIfPresentTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvVerifyTsErrorIfNotPresentLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dsVerifyTsErrorIfNotPresentTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionDs" value="verifyOrFailIfNotPresent" type="radio" checked={this.props.defaultState.signatureTsVerifyOption === 'verifyOrFailIfNotPresent'} onChange={this.tsVerifyHendler} />
	                                    <span className="form-check-label" id="dsvVerifyTsErrorIfNotPresentTitle">{i18next.t("ds:verifyTsErrorIfNotPresentTitle")}</span>
	                                </label>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <div>
	                    <label style={{"cursor": "pointer"}} data-toggle="collapse" href={null} onClick={this.expandDataVerifyTs.bind(this)} aria-expanded={this.props.defaultState.expandDataVerifyTs} aria-controls="dsvLeftParamAreaDataTsCheck" role="button">
	                        {
								this.props.defaultState.expandDataVerifyTs ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
									: <i className="fa fa-chevron-right" aria-hidden="true"></i>
							}
	                        &nbsp;
	                        <span id="dsvDataVerifyTsMode">{i18next.t("ds:dataVerifyTsMode")}</span>
	                    </label>
	                    <div id="dsvLeftParamAreaDataTsCheck" className={this.state.collapseDataVerifyTsShow} aria-expanded="true">
	                        <div className="form-check">
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvDataIgnoreTsLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dataIgnoreTsTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionData" value="ignore" type="radio" checked={this.props.defaultState.dataTsVerifyOption === 'ignore'} onChange={this.tsVerifyTypeHendler} />
	                                    <span className="form-check-label" id="dsvDataIgnoreTsTitle">{i18next.t("ds:ignoreTsTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvDataVerifyTsIfPresentLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dataVerifyTsIfPresentTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionData" value="verifyIfPresent" type="radio" checked={this.props.defaultState.dataTsVerifyOption === 'verifyIfPresent'} onChange={this.tsVerifyTypeHendler} />
	                                    <span className="form-check-label" id="dsvDataVerifyTsIfPresentTitle">{i18next.t("ds:verifyTsIfPresentTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label id="dsvDataVerifyTsErrorIfNotPresentLabel" data-placement="top" data-toggle="tooltip" className="grey-tooltip" title={i18next.t("ds:dataVerifyTsErrorIfNotPresentTooltip")}>
	                                    <input className="form-check-input" name="dsvTsCheckOptionData" value="verifyOrFailIfNotPresent" type="radio" checked={this.props.defaultState.dataTsVerifyOption === 'verifyOrFailIfNotPresent'} onChange={this.tsVerifyTypeHendler} />
	                                    <span className="form-check-label" id="dsvDataVerifyTsErrorIfNotPresentTitle">{i18next.t("ds:verifyTsErrorIfNotPresentTitle")}</span>
	                                </label>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	                <div>
	                	{
                        	this.state.showUpgradeDSCard
                        	? <div className="radio ml-tool-panel" style={{paddingLeft: "7px"}}>
                                <label title={i18next.t("ds:modifyDSTooltip")} style={{"cursor": "pointer"}}>
                                    <input className="form-check-input" type="checkbox" checked={this.props.defaultState.showUpgradeCard === true} onChange={this.showUpgradeCardHandler} />
                                    <span className="form-check-label" id="dscAddDsToExistingOneTitle">{i18next.t("ds:modifyDSTitle")}</span>
                                </label>
                            </div>
                            : null
                        }
	                </div>
	                <div>
	                	{
                        	this.props.base.qrCodeUrl !== undefined && this.props.base.qrCodeUrl.length > 0
                        	? <div className="radio ml-tool-panel" style={{paddingLeft: "7px"}}>
                                <label title={i18next.t("ds:generateQRTitle")} style={{"cursor": "pointer"}}>
                                    <input className="form-check-input" type="checkbox" checked={this.props.defaultState.generateQR === true} onChange={this.setGenerateQRHandler} />
                                    <span className="form-check-label" id="dscAddDsToExistingOneTitle">{i18next.t("ds:generateQRTitle")}</span>
                                </label>
                            </div>
                            : null
                        }
	                </div>
	            </div>
	        </div>
	    </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        defaultState: state.verifyDSOptionsReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	expandDS: expandDS,
    	expandTSVerify: expandTSVerify,
    	expandDataVerifyTs: expandDataVerifyTs,
    	setSignatureType: setSignatureType,
    	setTsCheck: setTsCheck,
    	setTsVerifyType: setTsVerifyType,
    	setVerifyDSOptionsExpand,
    	setShowUpgradeCard,
    	clearVerifyResultData, clearModifiedDSData,
    	clearFileSignedData, setGenerateQR,
    	clearVerifyResultText, clearTextSignedData,
		clearUpgradedDSInBase64
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDSOptionsContainer);