import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux';
import { expandEncType, setCertType, setEncryptOptionsExpand } from '../actions/localStates'

class EncryptOptionsContainer extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
			collapseEncParamsShow: "",
			collapseEncryptOptionsShow: ""
	    }

	    this.setCertTypeHendler = this.setCertTypeHendler.bind(this)
	}

	expandEncType() {
		this.props.actions.expandEncType(this.props.defaultState.expandEncType)
		this.props.defaultState.expandEncType ? this.setState({collapseEncParamsShow: "collapse"}) : this.setState({collapseEncParamsShow: "collapse show"})
	}

	expandEncryptOptions() {
		this.props.actions.setEncryptOptionsExpand(this.props.expandedCardsReducer.encryptOptionsExpanded)
		this.props.expandedCardsReducer.encryptOptionsExpanded ? this.setState({collapseEncryptOptionsShow: "card-body collapse"}) : this.setState({collapseEncryptOptionsShow: "card-body collapse show"})
	}

	componentDidMount() {
		this.props.defaultState.expandEncType ? this.setState({collapseEncParamsShow: "collapse show"}) : this.setState({collapseEncParamsShow: "collapse"})
		this.props.expandedCardsReducer.encryptOptionsExpanded ? this.setState({collapseEncryptOptionsShow: "card-body collapse show"}) : this.setState({collapseEncryptOptionsShow: "card-body collapse"})
	}

	setCertTypeHendler(e) {
		this.props.actions.setCertType(e.target.value)
	}

	render() {
		return (
			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{"paddingBottom": "10px"}}>
				<div className="card">
		            <div className="card-header" onClick={this.expandEncryptOptions.bind(this)}>
						{i18next.t("enc:encryptionParamsTitle")}
		            </div>
	            <div id="encryptionLeftParamArea" className={this.state.collapseEncryptOptionsShow}>
	                <div>
	                    <label style={{"cursor": "pointer"}} data-toggle="collapse" onClick={this.expandEncType.bind(this)} role="button" aria-expanded={this.props.defaultState.expandEncType}>
	                        {
								this.props.defaultState.expandEncType ? <i className="fa fa-chevron-down" aria-hidden="true"></i>
									: <i className="fa fa-chevron-right" aria-hidden="true"></i>
							}
							&nbsp;
	                        <span id="addWhileEncryptTitle">{i18next.t("enc:addWhileEncryptTitle")}</span>
	                    </label>
	                    <div className={this.state.collapseEncParamsShow}>
	                        <div className="form-check">
	                            <div className="radio ml-tool-panel">
	                                <label title={i18next.t("enc:signerAndCaCertTooltip")}>
	                                    <input className="form-check-input" type="radio" name="encEmbedOption" value="signerAndCaCert" checked={this.props.defaultState.embedCertificateType === "signerAndCaCert"} onChange={this.setCertTypeHendler} />
	                                    <span className="form-check-label">{i18next.t("enc:signerAndCaCertTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label title={i18next.t("enc:signerCertTooltip")}>
	                                    <input className="form-check-input" type="radio" name="encEmbedOption" value="signerCert" checked={this.props.defaultState.embedCertificateType === "signerCert"} onChange={this.setCertTypeHendler} />
	                                    <span className="form-check-label">{i18next.t("enc:signerCertTitle")}</span>
	                                </label>
	                            </div>
	                            <div className="radio ml-tool-panel">
	                                <label title={i18next.t("enc:encNothingTooltip")}>
	                                    <input className="form-check-input" type="radio" name="encEmbedOption" value="nothing" checked={this.props.defaultState.embedCertificateType === "nothing"} onChange={this.setCertTypeHendler} />
	                                    <span className="form-check-label">{i18next.t("enc:encNothingTitle")}</span>
	                                </label>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	    </div>
		)
	}
}

function mapStateToProps(state) {
    return {
    	language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        defaultState: state.encryptOptionsReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    expandEncType,
	    setCertType,
	    setEncryptOptionsExpand
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EncryptOptionsContainer);